import { memo, useLayoutEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { Config, resource, Strings, useData } from "ripple";
import { BottomLeftConnectedLogo, BottomRightMuseumLogo } from "../../common";
import useJoinButtonClick from "../../hooks/use-join-button-click";
import {
  CallToAction,
  Content,
  CustomCodeTextField,
  JoinButton,
  Page,
  PreTitle,
  Title,
  TitleContainer,
  TitleImage,
  Titles,
  TitleSection,
  TitleSplitter,
} from "./styled";

const StudentLandingPage = memo(() => {
  const { code: codeFromURL } = useParams();
  const client = useData((data) => data.root);

  const [joinCode, setJoinCode] = useState("");
  const [isValid, setIsValid] = useState(false);

  const onJoinButtonClick = useJoinButtonClick(joinCode);

  useLayoutEffect(() => {
    if (!codeFromURL) return;
    localStorage.setItem("session-code", codeFromURL);
  }, [codeFromURL]);

  const firstLanguage = Config.language.supported[0];
  const secondLanguage = Config.language.supported[1];

  return (
    <Page>
      {codeFromURL ? (
        <Redirect from="/join" to="/game/components" />
      ) : (
        <>
          <Titles>
            <TitleSection>
              <TitleContainer>
                <PreTitle>{Strings.localized("WelcomeTo", firstLanguage)}</PreTitle>
                <Title>{client?.wantedText("Title", firstLanguage).value.replace("RESOURCE", "<br/>Resource")}</Title>
                <TitleImage style={{ top: "-1.4vh", right: "2.5vh" }} />
              </TitleContainer>
              <CallToAction>{Strings.localized("StudentEnterSessionCode", firstLanguage)}</CallToAction>
            </TitleSection>
            <TitleSplitter />
            <TitleSection style={{ marginLeft: "9vh" }}>
              <TitleContainer>
                <PreTitle>{Strings.localized("WelcomeTo", secondLanguage)}</PreTitle>
                <Title>{client?.wantedText("Title", secondLanguage)}</Title>
                <TitleImage style={{ top: "-1.4vh", right: "-4vh" }} />
              </TitleContainer>
              <CallToAction>{Strings.localized("StudentEnterSessionCode", secondLanguage)}</CallToAction>
            </TitleSection>
          </Titles>
          <Content>
            <CustomCodeTextField value={joinCode} setValue={setJoinCode} setIsValid={setIsValid} />
            <JoinButton disabled={!isValid} onClick={onJoinButtonClick}>
              {Strings.localized("JoinButton")}
            </JoinButton>
          </Content>
          <BottomLeftConnectedLogo src={resource("images/Logo_ConnectedThroughMinerals.png")} />
          <BottomRightMuseumLogo />
        </>
      )}
    </Page>
  );
});

export default StudentLandingPage;
