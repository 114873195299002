import _ from "lodash";
import { Map, Point, resource, useData } from "ripple";
import { includesElement } from "../../../logic";
import useCompleted from "../../hooks/use-completed";
import {
  ComponentButton,
  ComponentButtonElement,
  ComponentButtonImage,
  ComponentButtonText,
  ComponentLine,
  ComponentsMap,
  PartSelectionContentRoot,
  Title,
} from "./styled";

const ComponentsContent = ({ ...rest }) => {
  const client = useData((data) => data.root);

  const completed = useCompleted();

  const renderComponentButton = (node) => {
    const color = node.wantedText("Color").value;
    const pointing = node.wantedText("Pointing").value;
    const position = Point.parse(node.wantedText("Position").value);

    const isTask1Completed = completed.includes(node.id);
    const isTask2Completed = _.every(node.children, (element) => includesElement(completed, element));
    const iconSrc =
      isTask1Completed && isTask2Completed ? resource("images/Completed_Icon.png") : resource("images/Gear_Icon.png");

    return (
      <ComponentButtonElement
        key={node.id}
        position={{ left: `${position?.x * 100}%`, top: `${position?.y * 100}%` }}
        anchor={pointing === "left" ? { x: 0, y: 0.5 } : { x: 1, y: 0.5 }}
        scaling={Map.Element.scaleToScreen}
      >
        {pointing === "left" && <ComponentLine color={color} />}
        <ComponentButton color={color} navigate={{ node, type: "team" }}>
          {pointing === "left" && <ComponentButtonImage pointing={pointing} src={iconSrc} />}
          <ComponentButtonText>{node.wantedText("Title").value}</ComponentButtonText>
          {pointing === "right" && <ComponentButtonImage pointing={pointing} src={iconSrc} />}
        </ComponentButton>
        {pointing === "right" && <ComponentLine color={color} />}
      </ComponentButtonElement>
    );
  };

  return (
    <PartSelectionContentRoot {...rest}>
      <Title>{client.wantedText("ComponentsTitle")}</Title>
      <ComponentsMap>
        <Map.ImageContent interactive={false} src={client.wantedMedia("ComponentsImage", "ComponentsImage")}>
          {client.children.map(renderComponentButton)}
        </Map.ImageContent>
      </ComponentsMap>
    </PartSelectionContentRoot>
  );
};

ComponentsContent.propTypes = {};

export default ComponentsContent;
