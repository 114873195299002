import { transparentize } from "polished";
import { Dropzone } from "ripple";
import styled from "styled-components";
import { greenColor } from "../../common";

export const MapDropzoneRoot = styled(Dropzone)`
  transition: all 50ms ease-in-out;
  box-shadow: ${(props) => (props.isOver ? `0 0 1vh ${transparentize(0.3, greenColor)} inset` : "none")};
  border: ${(props) => (props.isOver ? `2px solid ${greenColor}` : "none")};
`;

export const Visuals = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const InvalidVisuals = styled(Visuals)`
  box-shadow: 0 0 2vh red inset;

  transition: opacity 200ms ease-in-out;
  pointer-events: none;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const ValidVisuals = styled(Visuals)`
  box-shadow: 0 0 2vh green inset;

  transition: opacity 500ms ease-in-out;
  pointer-events: none;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;
