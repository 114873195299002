import PropTypes from "prop-types";
import { Strings, TextSrcPropType } from "ripple";
import { OkButton, PopupIcon, PopupText, TaskCompletionPopupRoot } from "./styled";

const TaskCompletionPopup = ({ text, onDismiss, ...rest }) => {
  return (
    <TaskCompletionPopupRoot {...rest}>
      <PopupIcon />
      <PopupText>{text}</PopupText>
      <OkButton onClick={onDismiss}>{Strings.localized("OkButtonTitle")}</OkButton>
    </TaskCompletionPopupRoot>
  );
};

TaskCompletionPopup.propTypes = {
  text: TextSrcPropType,
  onDismiss: PropTypes.func,
};

export default TaskCompletionPopup;
