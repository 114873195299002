import styled from "styled-components";
import { StandardSubtitle, StandardTitle } from "../../common";

export const Task1ContentRoot = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Title = styled(StandardTitle)``;

export const Subtitle = styled(StandardSubtitle)``;
