import { Image, Page as RipplePage, resource, staggerStep } from "ripple";
import styled from "styled-components";
import { StandardPillButton } from "../../common";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomPillButton = styled(StandardPillButton)`
  width: 14vmax;
  margin: 0 2vmax;
`;

export const LeftLanguageButton = staggerStep({ transition: "slide-in-and-fade-from-left", order: 0 })(
  styled(CustomPillButton)``
);

export const RightLanguageButton = staggerStep({ transition: "slide-in-and-fade-from-right", order: 0 })(
  styled(CustomPillButton)``
);

export const Decoration1 = styled(Image).attrs((props) => ({ src: resource("images/Decorations_A.png") }))`
  position: absolute;
  top: 14vh;
  right: 10vh;
  width: 8vh;
  height: 8vh;
`;

export const Decoration2 = styled(Image).attrs((props) => ({ src: resource("images/Decorations_B.png") }))`
  position: absolute;
  bottom: 20vh;
  left: 12vh;
  width: 10vh;
`;

export const Decoration3 = styled(Image).attrs((props) => ({ src: resource("images/Decorations_C.png") }))`
  position: absolute;
  bottom: 18vh;
  right: 40vh;
  width: 8vh;
`;

export const Decoration4 = styled(Image).attrs((props) => ({ src: resource("images/Decorations_D.png") }))`
  position: absolute;
  bottom: 18vh;
  right: 24vh;
  width: 9vh;
`;
