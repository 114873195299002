import styled from "styled-components";
import { darkGreenColor } from "../../common";

export const CodeTextFieldRoot = styled.input.attrs((props) => ({ maxLength: 7 }))`
  height: 12vh;
  border-radius: 2vh;
  border: 1px solid ${darkGreenColor};
  font-family: "Futura-Medium";
  letter-spacing: 0.6vh;
  color: #818181;
  font-size: 7.5vh;
  text-align: center;
  color: ${darkGreenColor};
`;
