import { Image, resource, Text } from "ripple";
import styled from "styled-components";

export const AppTitleBlockRoot = styled.div``;

export const PreTitle = styled(Text)`
  font-family: "Futura-Bold";
  font-size: 3.5vh;
  color: white;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Title = styled(Text)`
  font-family: "Futura-CondensedExtraBold";
  color: white;
  font-size: 5vh;
  text-transform: uppercase;
`;

export const TitleImage = styled(Image).attrs((props) => ({ src: resource("images/Boat_Icon_Red.svg") }))`
  margin-left: 3vh;
  height: 8vh;
  path,
  polygon,
  line {
    stroke: white;
  }
`;
