import { Image, Page as RipplePage, resource, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { StandardPillButton } from "../../common";
import AppTitleBlock from "../../components/app-title-block";
import CodeTextField from "../../components/code-text-field";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleSplitter = styled.div`
  margin-top: -3vh;
  width: 0.25vh;
  height: 36vh;
  background-color: white;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PreTitle = styled(Text)`
  font-family: "Futura-Bold";
  font-size: 3.5vh;
  color: white;
`;

export const Title = styled(Text)`
  font-family: "Futura-CondensedExtraBold";
  color: white;
  font-size: 5vh;
  text-transform: uppercase;
`;

export const TitleImage = styled(Image).attrs((props) => ({ src: resource("images/Boat_Icon_Red.svg") }))`
  position: absolute;
  width: 14vh;
  path,
  polygon,
  line {
    stroke: white;
  }
`;

export const CustomAppTitleBlock = staggerStep({ transition: "slide-in-and-fade-from-top" })(styled(AppTitleBlock)`
  margin-top: -16vh;
`);

export const Content = staggerStep({ transition: "slide-in-and-fade-from-bottom" })(
  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `
);

export const CallToAction = styled(Text)`
  margin-top: 4vh;
  font-size: 3.5vmin;
  font-family: "GothamLight";
  width: 60vh;
`;

export const CustomCodeTextField = styled(CodeTextField)`
  margin-top: 12vh;
  width: 60vh;
`;

export const JoinButton = styled(StandardPillButton)`
  margin-top: 2vh;
  align-self: flex-end;
  min-width: 18vh;
`;
