import { Image, Map, Text } from "ripple";
import styled from "styled-components";
import { StandardPillButton, StandardTitle } from "../../common";

export const PartSelectionContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Title = styled(StandardTitle)`
  margin-top: 2vh;
  font-family: "Futura-CondensedMedium";
  font-size: 6vh;
  align-self: center;
`;

export const ComponentsMap = styled(Map)`
  margin-top: 6vh;
  margin-bottom: 4vh;
  flex: 1;
  min-height: 0; /* Prevents overflow outside of parent! */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ComponentsImage = styled(Image).attrs((props) => ({ scaling: "fit" }))`
  margin-top: 6vh;
  margin-bottom: 4vh;
  flex: 1;
`;

export const ComponentButtonElement = styled(Map.Element)`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ComponentButton = styled(StandardPillButton)`
  background-color: ${(props) => props.color};
  box-shadow: 0 0.2vh 0.5vh rgba(0, 0, 0, 0.5);
  height: 5vh;
  border-radius: 2.5vh;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ComponentButtonText = styled(Text)`
  text-shadow: 0 0.2vh 0.2vh rgba(0, 0, 0, 0.3);
  font-size: 1.4vh;
`;

export const ComponentButtonImage = styled(Image)`
  margin-left: ${(props) => (props.pointing === "left" ? -2 : 1)}vh;
  margin-right: ${(props) => (props.pointing === "right" ? -2 : 1)}vh;
  height: 4.5vh;
  width: 4.5vh;
`;

export const ComponentLine = styled.div`
  height: 2px;
  width: 100px;
  border: 2px dashed ${(props) => props.color};
`;
