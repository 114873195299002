import { memo } from "react";
import { Config, Localization, Navigator, resource, Strings } from "ripple";
import { BottomLeftConnectedLogo, BottomRightMuseumLogo } from "../../common";
import {
  Buttons,
  Decoration1,
  Decoration2,
  Decoration3,
  Decoration4,
  LeftLanguageButton,
  Page,
  RightLanguageButton,
} from "./styled";

const MainPage = memo(() => {
  const createOnLanguageButtonClick = (language) => () => {
    Localization.switchToLanguage(language);
    Navigator.navigate({ type: "setup" });
  };

  return (
    <Page>
      <Buttons>
        <LeftLanguageButton onClick={createOnLanguageButtonClick(Config.language.supported[0])}>
          {Strings.localized("LanguageName", Config.language.supported[0])}
        </LeftLanguageButton>
        <RightLanguageButton onClick={createOnLanguageButtonClick(Config.language.supported[1])}>
          {Strings.localized("LanguageName", Config.language.supported[1])}
        </RightLanguageButton>
      </Buttons>
      <Decoration1 />
      <Decoration2 />
      <Decoration3 />
      <Decoration4 />
      <BottomLeftConnectedLogo src={resource("images/Logo_ConnectedThroughMinerals.png")} />
      <BottomRightMuseumLogo />
    </Page>
  );
});

export default MainPage;
