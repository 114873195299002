import PropTypes from "prop-types";
import { useCallback } from "react";
import { CodeTextFieldRoot } from "./styled";

const codify = (code) => {
  const typed = code.replace("-", "");
  if (typed.length <= 3) return typed;
  if (typed.length > 3) return `${typed.slice(0, 3)}-${typed.slice(-(typed.length - 3))}`;
};

const CodeTextField = ({ value, setValue, setIsValid, ...rest }) => {
  const onInternalChange = useCallback(
    (event) => {
      const value = event.target.value.replace(/[^0-9-]/, "");
      const code = codify(value);
      setValue?.(code);
      setIsValid?.(/\d\d\d-\d\d\d/.test(code));
    },
    [setIsValid, setValue]
  );

  return <CodeTextFieldRoot {...rest} onChange={onInternalChange} value={value}></CodeTextFieldRoot>;
};

CodeTextField.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  setIsValid: PropTypes.func,
};

export default CodeTextField;
