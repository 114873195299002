import { Image, Page as RipplePage, Revealer, RouteTransitioner, staggerStep } from "ripple";
import styled from "styled-components";
import ElementBox from "../../components/element-box";
import Sidebar from "../../components/sidebar";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: row;
  background-color: white;
`;

export const CustomSidebar = staggerStep({ transition: "slide-in-and-out-from-left" })(styled(Sidebar)``);

export const Content = staggerStep({ transition: "scale-up-fade" })(
  styled.div`
    background-color: white;
    flex: 1;
    display: flex;
  `
);

export const FullsizeRouteTransitioner = styled(RouteTransitioner)`
  flex: 1;
`;

export const DragContainer = styled.div`
  position: relative;
`;

export const DragShadow = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  box-shadow: 0 2vh 4vh black, 0 1vh 4vh black, 0 0 4vh black;
`;

export const DragImage = styled(Image)`
  width: 4vh;
  height: 4vh;
  transform: rotate3d(0, 0, 1, -15deg);
`;

export const DragElementBox = styled(ElementBox)`
  width: 4vh;
  height: 4vh;
  font-size: 2vh;
  transform: rotate3d(0, 0, 1, -15deg);
`;

export const PopupRevealer = styled(Revealer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
`;
