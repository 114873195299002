import anime from "animejs";
import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import { MediaSrcPropType } from "ripple";
import { MapLineRoot } from "./styled";

const MapLine = ({ src, frame, dashOffset, ...rest }) => {
  const imageRef = useRef(null);

  const onLoad = useCallback(() => {
    const svg = imageRef.current.querySelector("svg");

    const dashed = svg.querySelector("path");

    const filled = dashed.cloneNode(true);
    dashed.parentNode.appendChild(filled);

    dashed.style.opacity = 0;

    anime({
      targets: [dashed],
      duration: 1000,
      easing: "linear",
      strokeDashoffset: [0, dashOffset],
      loop: true,
    });

    anime
      .timeline({
        easing: "easeInOutSine",
      })
      .add({
        targets: [filled],
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 1500,
      })
      .add({
        targets: [dashed],
        duration: 500,
        opacity: 1,
      })
      .add(
        {
          targets: [filled],
          duration: 500,
          opacity: 0,
        },
        "-=500"
      );
  }, [dashOffset]);

  return <MapLineRoot ref={imageRef} {...rest} src={src} frame={frame} onLoad={onLoad} />;
};

MapLine.propTypes = {
  src: MediaSrcPropType,
  frame: PropTypes.object,
  dashOffset: PropTypes.number,
};

MapLine.defaultProps = {
  dashOffset: -20,
};

export default MapLine;
