import _ from "lodash";
import PropTypes from "prop-types";
import { Rect, resource, useData } from "ripple";
import { v4 as uuidv4 } from "uuid";
import MapLine from "../map-line";
import { Dropzones, MapContainer, MapImage, MapPin, ResourceMapDropzone, ResourceMapRoot, Visuals } from "./styled";

const ResourceMap = ({ onCorrectDrop, drawNodePins, drawAssembly, drawStore, ...rest }) => {
  const client = useData((data) => data.root);

  return (
    <ResourceMapRoot {...rest}>
      <MapContainer>
        <MapImage src={resource("images/Grid_Map.png")} />
        <Dropzones>
          <ResourceMapDropzone id="A1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S1" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S2" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S3" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S4" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S5" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S6" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S7" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S8" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S9" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="A10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="B10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="C10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="D10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="E10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="F10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="G10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="H10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="I10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="J10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="K10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="L10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="M10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="N10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="O10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="P10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="Q10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="R10" onDrop={onCorrectDrop} />
          <ResourceMapDropzone id="S10" onDrop={onCorrectDrop} />
        </Dropzones>
        <Visuals>
          {drawNodePins
            .filter((n) => n.semantic === "PositionedElement")
            .filter((n) => drawNodePins.includes(n.parent.parent))
            .map((node) => (
              <MapLine
                key={node.id}
                src={node.wantedMedia("MapLine", "Vector")}
                frame={Rect.parse(node.wantedText("MapLineFrame").value)}
              />
            ))}
          {drawAssembly && drawStore && (
            <MapLine
              src={client.wantedMedia("MapAssemblyStoreLine", "Vector")}
              frame={Rect.parse(client.wantedText("MapAssemblyStoreLineFrame").value)}
            />
          )}
          {drawAssembly &&
            drawNodePins
              .filter((n) => n.semantic === "Component")
              .map((node) => (
                <MapLine
                  key={node.id}
                  src={node.wantedMedia("AssemblyLine", "Vector")}
                  frame={Rect.parse(node.wantedText("AssemblyLineFrame").value)}
                  dashOffset={-16}
                />
              ))}
        </Visuals>
        <Visuals>
          {_.uniqBy(drawNodePins, (n) =>
            n.semantic === "Component"
              ? uuidv4() // A hacky way to never "uniquify" components
              : n.wantedText("MapCoordinates").value + n.parent.optionalText("Symbol").value
          ).map((node) => (
            <MapPin
              key={node.id}
              src={node.wantedMedia("MapPin", "PreserveSizeWithAlpha")}
              frame={Rect.parse(node.wantedText("MapPinFrame").value)}
            />
          ))}
        </Visuals>
        <Visuals>
          {drawAssembly && (
            <MapPin
              src={client.wantedMedia("MapAssemblyPin", "PreserveSizeWithAlpha")}
              frame={Rect.parse(client.wantedText("MapAssemblyPinFrame").value)}
            />
          )}
          {drawStore && (
            <MapPin
              src={client.wantedMedia("MapStorePin", "PreserveSizeWithAlpha")}
              frame={Rect.parse(client.wantedText("MapStorePinFrame").value)}
            />
          )}
        </Visuals>
      </MapContainer>
    </ResourceMapRoot>
  );
};

ResourceMap.propTypes = {
  onCorrectDrop: PropTypes.func,
  drawNodePins: PropTypes.arrayOf(PropTypes.object),
  drawAssembly: PropTypes.bool,
  drawStore: PropTypes.bool,
};

export default ResourceMap;
