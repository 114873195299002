import { Strings } from "ripple";
import { Message, PortraitBlockerContentRoot } from "./styled";

const PortraitBlockerContent = ({ ...rest }) => {
  return (
    <PortraitBlockerContentRoot {...rest}>
      <Message>{Strings.localized("PortraitBlockingMessage")}</Message>
    </PortraitBlockerContentRoot>
  );
};

PortraitBlockerContent.propTypes = {};

export default PortraitBlockerContent;
