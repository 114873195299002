import { memo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, useLocation } from "react-router";
import { Config, RouteTransitioner, useConstant, useData, useDebug, useRemoteState } from "ripple";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { almostBlack, greenColor } from "./common";
import AppOverlay from "./components/app-overlay";
import AppUnderlay from "./components/app-underlay";
import GracePopup from "./components/grace-popup";
import PortraitBlocker from "./components/portrait-blocker";
import PortraitBlockerContent from "./components/portrait-blocker-content";
import GamePage from "./pages/game-page";
import LanguagePage from "./pages/language-page";
import StudentLandingPage from "./pages/student-landing-page";
import TeacherLandingPage from "./pages/teacher-landing-page";
/* APP_PAGE_IMPORTS_INSERTION_POINT */

const StyledRippleRoot = createGlobalStyle`
  #app .root {
    background-color: ${greenColor};
  }
`;

const StyledAppRoot = styled.div`
  font-family: "Gotham";
  color: ${almostBlack};
`;

export const AppRootRouteTransitioner = styled(RouteTransitioner)`
  width: 100%;
  height: 100%;
`;

const AppRoot = memo(() => {
  const location = useLocation();
  const debug = useDebug();
  const data = useData();
  const remote = useRemoteState();

  const themeName = Config.theme;
  const themeObject = Config.themes[themeName];
  if (typeof themeObject === "undefined") throw new Error(`No theme named '${Config.theme}'`);

  const theme = {
    debug,
    name: themeName,
    ...themeObject,
  };

  // By default, transition only occurs when the first path component changes.
  // However, this might not make sense in all apps. Feel free to change this
  // to match your needs! For example, if you want a transition to occur every
  // time the URL changes (including the query string), use
  // `location.pathname + location.search` as a key.
  const transitionKey = location.pathname.split("/")[1];

  // Do not render pages as long as the data isn't available, as most pages depend on it heavily.
  const protect = (Component) => data && (!Config.ipc.enabled || !!remote) && Component;

  const queryClient = useConstant(() => new QueryClient());

  return (
    <ThemeProvider theme={theme}>
      <StyledRippleRoot />
      <QueryClientProvider client={queryClient}>
        <StyledAppRoot style={{ width: "100%", height: "100%" }}>
          <AppUnderlay />
          <AppRootRouteTransitioner transitionKey={transitionKey} location={location}>
            <Route path="/language" component={protect(LanguagePage)} />
            <Route path="/setup" component={protect(TeacherLandingPage)} />
            <Route path="/join/:code?" component={protect(StudentLandingPage)} />
            <Route path="/game" component={protect(GamePage)} />
            {/* APP_ROUTES_INSERTION_POINT */}
          </AppRootRouteTransitioner>
          <GracePopup />
          <AppOverlay />
          <PortraitBlocker>
            <PortraitBlockerContent />
          </PortraitBlocker>
        </StyledAppRoot>
        {__DEV__ && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </ThemeProvider>
  );
});

export default AppRoot;
