import { useCallback } from "react";
import { useParams } from "react-router";
import { Strings, useData } from "ripple";
import Api from "../../../api";
import useCompleted from "../../hooks/use-completed";
import useStore from "../../hooks/use-store";
import useTask1Completed from "../../hooks/use-task1-completed";
import useTask2Completed from "../../hooks/use-task2-completed";
import ResourceMap from "../resource-map";
import { Subtitle, Task1ContentRoot, Title } from "./styled";

const TaskContent = ({ ...rest }) => {
  const { id } = useParams();
  const component = useData((data) => data.requiredNode(id));

  const completed = useCompleted();
  const isTask1Completed = useTask1Completed(component);
  const isTask2Completed = useTask2Completed(component);

  const code = localStorage.getItem("session-code");
  const setOptimisticallyCompleted = useStore((state) => state.setOptimisticallyCompleted);
  const onCorrectDrop = useCallback(
    (dropzoneId, droppableNode) => {
      const id = (() => {
        if (droppableNode.semantic === "Component") return droppableNode.id;
        return droppableNode.children.filter(
          (positioned) => positioned.requiredText("MapCoordinates").value === dropzoneId
        )[0].id;
      })();

      setOptimisticallyCompleted(id);
      Api.complete(code, id);
    },
    [code, setOptimisticallyCompleted]
  );

  const drawNodePins = [];
  if (isTask1Completed) drawNodePins.push(component);
  component.children
    .flatMap((element) => element.children)
    .forEach((positioned) => {
      if (completed.includes(positioned.id)) drawNodePins.push(positioned);
    });

  const subtitleKey = (() =>
    !isTask1Completed
      ? "Task1ContentSubtitle"
      : isTask2Completed
      ? "Task2ContentCompletedSubtitle"
      : "Task2ContentSubtitle")();

  return (
    <Task1ContentRoot {...rest}>
      <Title>{Strings.localized("ResourceMap")}</Title>
      <Subtitle>{component.wantedText(subtitleKey)}</Subtitle>
      <ResourceMap onCorrectDrop={onCorrectDrop} drawNodePins={drawNodePins} />
    </Task1ContentRoot>
  );
};

TaskContent.propTypes = {};

export default TaskContent;
