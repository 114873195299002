import { Image } from "ripple";
import styled from "styled-components";

export const MapLineRoot = styled(Image)`
  position: absolute;
  top: ${(props) => props.frame?.y * 100}%;
  left: ${(props) => props.frame?.x * 100}%;
  width: ${(props) => props.frame?.width * 100}%;
  height: ${(props) => props.frame?.height * 100}%;
`;
