import { memo, useCallback, useState } from "react";
import { Clipboard, Localization, resource, Strings, Toast, useLanguage } from "ripple";
import Api from "../../../api";
import { BottomLeftConnectedLogo, BottomRightMuseumLogo } from "../../common";
import useJoinButtonClick from "../../hooks/use-join-button-click";
import {
  ChoppedCodeTextField,
  CodeTextFieldRow,
  Columns,
  ColumnSubtitle,
  ColumnTitle,
  ConfirmButton,
  ConfirmRow,
  Content,
  CopyLinkButton,
  CustomAppTitleBlock,
  FullCodeTextField,
  GenerateCodeButton,
  LeftColumn,
  Or,
  Page,
  RightColumn,
  Splitter,
  SplitterLine,
} from "./styled";

const TeacherLandingPage = memo(() => {
  const language = useLanguage();
  const [createCode, setCreateCode] = useState("");

  const [joinCode, setJoinCode] = useState("");
  const [joinCodeIsValid, setJoinCodeIsValid] = useState(false);

  const onCreateSessionClick = useCallback(async () => {
    Api.createSession(Localization.getCurrentLanguage())
      .then((data) => setCreateCode(data.code))
      .catch((e) => Toast.error(`Could not create session (${e.message})`));
  }, []);

  const onCopyLinkButtonClick = useCallback(() => {
    Clipboard.copy(`https://${location.hostname}${location.port ? `:${location.port}` : ""}/join/${createCode}`);
    Toast.info(Strings.localized("ClipboardCopySuccess").value);
  }, [createCode]);

  const onJoinCreatedButtonClick = useJoinButtonClick(createCode);
  const onJoinCreatedAsTeacherButtonClick = useCallback(() => {
    localStorage.setItem("specmode", true);
    onJoinCreatedButtonClick();
  }, [onJoinCreatedButtonClick]);

  const onJoinExistingButtonClick = useJoinButtonClick(joinCode);
  const onJoinExistingAsTeacherButtonClick = useCallback(() => {
    localStorage.setItem("specmode", true);
    onJoinExistingButtonClick();
  }, [onJoinExistingButtonClick]);

  return (
    <Page>
      <Content>
        <CustomAppTitleBlock />
        <Columns>
          <LeftColumn>
            <ColumnTitle>{Strings.localized("TeacherCreateSessionTitle")}</ColumnTitle>
            <ColumnSubtitle hide={!createCode}>{Strings.localized("TeacherCreateSessionSubtitle")}</ColumnSubtitle>
            <CodeTextFieldRow>
              <ChoppedCodeTextField disabled hide={!createCode} value={createCode} />
              <CopyLinkButton hide={!createCode} onClick={onCopyLinkButtonClick}>
                {Strings.localized("CopyLinkButton")}
              </CopyLinkButton>
              <GenerateCodeButton disableAfterClick hide={createCode} onClick={onCreateSessionClick}>
                {Strings.localized("GenerateCodeButton")}
              </GenerateCodeButton>
            </CodeTextFieldRow>
            <ConfirmRow hide={!createCode}>
              <ConfirmButton onClick={onJoinCreatedAsTeacherButtonClick}>
                {Strings.localized("JoinAsTeacherButton")}
              </ConfirmButton>
            </ConfirmRow>
          </LeftColumn>
          <Splitter>
            <SplitterLine />
            <Or>{Strings.localized("Or")}</Or>
            <SplitterLine />
          </Splitter>
          <RightColumn>
            <ColumnTitle>{Strings.localized("TeacherJoinSessionTitle")}</ColumnTitle>
            <ColumnSubtitle>{Strings.localized("TeacherJoinSessionSubtitle")}</ColumnSubtitle>
            <CodeTextFieldRow>
              <FullCodeTextField value={joinCode} setValue={setJoinCode} setIsValid={setJoinCodeIsValid} />
            </CodeTextFieldRow>
            <ConfirmRow>
              <ConfirmButton disabled={!joinCodeIsValid} onClick={onJoinExistingAsTeacherButtonClick}>
                {Strings.localized("JoinAsTeacherButton")}
              </ConfirmButton>
            </ConfirmRow>
          </RightColumn>
        </Columns>
      </Content>
      <BottomLeftConnectedLogo src={resource(`images/Logo_ConnectedThroughMinerals_${language.toUpperCase()}.png`)} />
      <BottomRightMuseumLogo />
    </Page>
  );
});

export default TeacherLandingPage;
