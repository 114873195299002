import { useQuery } from "react-query";
import Api from "../../api";
import useStore from "./use-store";

const useCompleted = (id) => {
  const code = localStorage.getItem("session-code");
  const { data } = useQuery("session", () => Api.getSession(code), { refetchInterval: 1000 });
  const optimisticallyCompleted = useStore((state) => state.optimisticallyCompleted);
  const all = [...optimisticallyCompleted, ...(data?.completed ?? [])];
  return Array.from(new Set(all));
};

export default useCompleted;
