import { useQuery } from "react-query";
import Api from "../../api";

const useSessionLanguage = (id) => {
  const code = localStorage.getItem("session-code");
  const { data } = useQuery("session", () => Api.getSession(code), { refetchInterval: 1000 });
  return data?.language;
};

export default useSessionLanguage;
