import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Audio, resource } from "ripple";
import Sequencer from "sequencer.js";
import { InvalidVisuals, MapDropzoneRoot, ValidVisuals } from "./styled";

const MapDropzone = ({ onDrop, ...rest }) => {
  const [isOver, setIsOver] = useState(false);

  const onOver = useCallback((isOver) => setIsOver(isOver), []);

  const [showInvalidVisuals, setShowInvalidVisuals] = useState(false);
  const [showValidVisuals, setShowValidVisuals] = useState(false);
  const onDropInternal = useCallback(
    (dropzoneId, droppableNode) => {
      const validCoordinates =
        droppableNode.semantic === "Component"
          ? [droppableNode.wantedText("MapCoordinates").value]
          : droppableNode.children.map((positionedElement) => positionedElement.wantedText("MapCoordinates").value);

      if (!validCoordinates.includes(dropzoneId)) {
        Audio.discrete("effects").play(resource("audio/bad-answer.mp3"));
        const sequencer = new Sequencer();
        sequencer.do(() => setShowInvalidVisuals(true));
        sequencer.doWait(250);
        sequencer.do(() => setShowInvalidVisuals(false));
      } else {
        Audio.discrete("effects").play(resource("audio/good-answer.mp3"));
        const sequencer = new Sequencer();
        sequencer.do(() => setShowValidVisuals(true));
        sequencer.doWait(500);
        sequencer.do(() => setShowValidVisuals(false));
        onDrop?.(dropzoneId, droppableNode);
      }
    },
    [onDrop]
  );

  return (
    <MapDropzoneRoot {...rest} onOver={onOver} onDrop={onDropInternal} isOver={isOver}>
      <InvalidVisuals show={showInvalidVisuals} />
      <ValidVisuals show={showValidVisuals} />
    </MapDropzoneRoot>
  );
};

MapDropzone.propTypes = {
  onDrop: PropTypes.func,
};

export default MapDropzone;
