// A function that takes navigation options and returns new navigation options with at least a path
// (and optionally more) or nothing, in which case navigation falls back to the originally provided
// navigation options. See `Navigator.navigate()` and `PagesConfigurator` (in Ripple) for details.

export default (nav) => {
  if (nav.type === "setup") return { path: "/setup" };
  if (nav.type === "components") return { path: "/game/components" };
  if (nav.type === "team") return { path: `/game/component/${nav.node.id}/team` };
  if (nav.type === "task") return { path: `/game/component/${nav.node.id}/task` };
  if (nav.type === "filters") return { path: `/game/filters` };
  // If (nav.node?.semantic === "MyNodeSemantic")
  //   return { path: `/example/${nav.node.id}`, replaceQuery: { something: nav.something } };
};
