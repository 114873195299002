import { Image } from "ripple";
import styled from "styled-components";

export const EmptySidebarContentRoot = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BottomLogo = styled(Image)`
  position: absolute;
  bottom: 1.2vh;
  width: 60%;
  align-self: center;
`;
