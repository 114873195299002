import { Image, resource, Text } from "ripple";
import styled from "styled-components";
import { darkGreenColor, StandardPillButton } from "../../common";

export const TaskCompletionPopupRoot = styled.div`
  background-color: white;
  outline: 0.5vh solid ${darkGreenColor};
  outline-offset: -1.2vh;
  padding: 3vh;
  width: 60vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PopupIcon = styled(Image).attrs((props) => ({ src: resource("images/Icon_Popup.png") }))`
  margin-bottom: 1.5vh;
  width: 50%;
`;

export const PopupText = styled(Text)`
  text-align: center;
`;

export const OkButton = styled(StandardPillButton)`
  margin-top: 3vh;
  min-width: 10vh;

  .hotspot {
    margin: -4vh;
  }
`;
