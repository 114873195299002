import produce from "immer";
import create from "zustand";

const useStore = create((set) => ({
  optimisticallyCompleted: [],
  setOptimisticallyCompleted: (id) =>
    set((state) => ({ optimisticallyCompleted: [...state.optimisticallyCompleted, id] })),

  forceEnabled: {},
  resetForceEnabled: (value) =>
    set((state) => {
      return { forceEnabled: value ?? {} };
    }),
  setForceEnabled: (key, value) =>
    set((state) => ({
      forceEnabled: produce(state.forceEnabled, (draft) => {
        draft[key] = value;
      }),
    })),

  disableAllComponents: false,
  setDisableAllComponents: (value) => set((state) => ({ disableAllComponents: value })),

  disableAllElements: false,
  setDisableAllElements: (value) => set((state) => ({ disableAllElements: value })),

  filtersDataSource: "completed",
  setFiltersDataSource: (value) => set((state) => ({ filtersDataSource: value })),
}));

export default useStore;
