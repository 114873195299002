import PropTypes from "prop-types";
import { ElementBoxRoot } from "./styled";

const ElementBox = ({ symbol, foreground, background, ...rest }) => {
  return (
    <ElementBoxRoot foreground={foreground} background={background} {...rest}>
      {symbol}
    </ElementBoxRoot>
  );
};

ElementBox.propTypes = {
  symbol: PropTypes.any,
  foreground: PropTypes.string,
  background: PropTypes.string,
};

ElementBox.defaultProps = {
  symbol: "Zx",
  foreground: "black",
  background: "white",
};

export default ElementBox;
