import { Text } from "ripple";
import styled from "styled-components";

export const ElementBoxRoot = styled(Text)`
  border: 2px solid ${(props) => props.foreground};
  color: ${(props) => props.foreground};
  background-color: ${(props) => props.background};

  font-family: "Futura-Medium";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
