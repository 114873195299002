import { Strings, useData } from "ripple";
import { AppTitleBlockRoot, PreTitle, Title, TitleImage, TitleRow } from "./styled";

const AppTitleBlock = ({ ...rest }) => {
  const client = useData((data) => data.root);
  return (
    <AppTitleBlockRoot {...rest}>
      <PreTitle>{Strings.localized("WelcomeTo")}</PreTitle>
      <TitleRow>
        <Title>{client?.wantedText("Title")}</Title>
        <TitleImage />
      </TitleRow>
    </AppTitleBlockRoot>
  );
};

AppTitleBlock.propTypes = {};

export default AppTitleBlock;
