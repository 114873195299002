import { memo, useCallback, useEffect, useRef, useState } from "react";
import { matchPath, Route, useLocation, useRouteMatch } from "react-router";
import { Config, DragPreview, Localization, PropsForwardingRoute, useData, useLastTruthy } from "ripple";
import ComponentsContent from "../../components/components-content";
import EmptySidebarContent from "../../components/empty-sidebar-content";
import FiltersContent from "../../components/filters-content";
import FiltersSidebarContent from "../../components/filters-sidebar-content";
import GameSidebarContent from "../../components/game-sidebar-content";
import TaskCompletionPopup from "../../components/task-completion-popup";
import TaskContent from "../../components/task-content";
import TeamContent from "../../components/team-content";
import useSessionLanguage from "../../hooks/use-session-language";
import useTask1Completed from "../../hooks/use-task1-completed";
import useTask2Completed from "../../hooks/use-task2-completed";
import {
  Content,
  CustomSidebar,
  DragContainer,
  DragElementBox,
  DragImage,
  DragShadow,
  FullsizeRouteTransitioner,
  Page,
  PopupRevealer,
} from "./styled";

const GamePage = memo(() => {
  const location = useLocation();
  const { url } = useRouteMatch();

  const match = matchPath(location.pathname, { path: "/game/component/:id" });
  const component = useData((data) => (match ? data.requiredNode(match.params.id) : null));

  const [taskCompletionPopupText, setTaskCompletionPopupText] = useState(false);
  const [lastTruthyTaskCompletionPopupText] = useLastTruthy(taskCompletionPopupText);
  const taskCompletionPopupTimeoutRef = useRef(null);

  const onPopupDismiss = useCallback(() => setTaskCompletionPopupText(null), []);

  const isTask1Completed = useTask1Completed(component);
  const previousIsTask1ComponentIdCompletedRef = useRef(null);
  useEffect(() => {
    if (isTask1Completed && !(previousIsTask1ComponentIdCompletedRef.current === component?.id)) {
      previousIsTask1ComponentIdCompletedRef.current = component.id;
      clearTimeout(taskCompletionPopupTimeoutRef.current);
      taskCompletionPopupTimeoutRef.current = setTimeout(
        () => setTaskCompletionPopupText(component.wantedText("Task1ContentCompletedSubtitle")),
        Config.taskCompletionPopupDelay
      );
    }
  }, [component, component?.id, isTask1Completed]);

  const isTask2Completed = useTask2Completed(component);
  const previousIsTask2ComponentIdCompletedRef = useRef(null);
  useEffect(() => {
    if (isTask2Completed && !(previousIsTask2ComponentIdCompletedRef.current === component?.id)) {
      previousIsTask2ComponentIdCompletedRef.current = component.id;
      clearTimeout(taskCompletionPopupTimeoutRef.current);
      taskCompletionPopupTimeoutRef.current = setTimeout(
        () => setTaskCompletionPopupText(component.wantedText("Task2ContentCompletedSubtitle")),
        Config.taskCompletionPopupDelay
      );
    }
  }, [component, component?.id, isTask2Completed]);

  // Hide the task completion popup when navigating to the filters map
  useEffect(() => {
    if (!component) setTaskCompletionPopupText(null);
  }, [component]);

  // Follow the session language
  const sessionLanguage = useSessionLanguage();
  useEffect(() => {
    if (Localization.getCurrentLanguage() === sessionLanguage || !sessionLanguage) return;
    Localization.switchToLanguage(sessionLanguage);
  }, [sessionLanguage]);

  const renderDragPreview = useCallback((node) => {
    return node.semantic === "Component" ? (
      <DragContainer>
        <DragShadow />
        <DragImage src={node.wantedMedia("FabricationIcon", "Icon")} />
      </DragContainer>
    ) : (
      <DragContainer>
        <DragShadow />
        <DragElementBox symbol={node.wantedText("Symbol")} />
      </DragContainer>
    );
  }, []);

  return (
    <Page>
      <CustomSidebar>
        <FullsizeRouteTransitioner transitionKey={location.pathname.split("/")[2]}>
          <Route path={`${url}/components`} component={EmptySidebarContent} />
          <PropsForwardingRoute path={`${url}/component/:id/team`} component={GameSidebarContent} />
          <PropsForwardingRoute path={`${url}/component/:id/task`} component={GameSidebarContent} />
          <PropsForwardingRoute path={`${url}/filters`} component={FiltersSidebarContent} />
        </FullsizeRouteTransitioner>
      </CustomSidebar>
      <Content>
        <FullsizeRouteTransitioner transitionKey={location.pathname}>
          <Route path={`${url}/components`} component={ComponentsContent} />
          <Route path={`${url}/component/:id/team`} component={TeamContent} />
          <PropsForwardingRoute path={`${url}/component/:id/task`} component={TaskContent} />
          <PropsForwardingRoute path={`${url}/filters`} component={FiltersContent} />
        </FullsizeRouteTransitioner>
        <PopupRevealer reveal={!!taskCompletionPopupText}>
          {isTask2Completed ? (
            <TaskCompletionPopup text={lastTruthyTaskCompletionPopupText} onDismiss={onPopupDismiss} />
          ) : isTask1Completed ? (
            <TaskCompletionPopup text={lastTruthyTaskCompletionPopupText} onDismiss={onPopupDismiss} />
          ) : null}
        </PopupRevealer>
      </Content>
      <DragPreview render={renderDragPreview} />
    </Page>
  );
});

export default GamePage;
