import { Config } from "ripple";

class Api {
  static get(path) {
    return fetch(`${Config.sessionServer.url}${path}`);
  }

  static post(path, body) {
    return fetch(`${Config.sessionServer.url}${path}`, {
      method: "POST",
      body,
      headers: { "Content-Type": "application/json" },
    });
  }

  static createSession(language) {
    return this.post(`/session/create`, JSON.stringify({ language }))
      .then((r) => {
        if (r.status !== 200) throw { message: "Failed to create session" };
        return r;
      })
      .then((r) => r.text())
      .then(JSON.parse);
  }

  static getSession(code) {
    return this.get(`/session/${code}`)
      .then((r) => {
        if (r.status === 404) throw { status: 404, message: "Failed to create session" };
        if (r.status !== 200) throw { message: "Failed to create session" };
        return r;
      })
      .then((r) => r.text())
      .then(JSON.parse);
  }

  static complete(code, id) {
    return this.post(`/session/${code}/complete`, JSON.stringify({ completed: id }));
  }
}

export default Api;
