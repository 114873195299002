import { transparentize } from "polished";
import { Button, Page as RipplePage, resource, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { almostBlack, darkGreenColor, StandardPillButton } from "../../common";
import AppTitleBlock from "../../components/app-title-block";
import CodeTextField from "../../components/code-text-field";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CustomAppTitleBlock = staggerStep({ transition: "slide-in-and-fade-from-top", order: 0 })(
  styled(AppTitleBlock)`
    margin-top: -16vh;
  `
);

export const Columns = styled.div`
  margin-top: 8vh;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Splitter = staggerStep({ order: 2 })(
  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  `
);

export const SplitterLine = styled.div`
  width: 2px;
  height: 20vmin;
  background-color: white;
`;

export const Or = styled(Text)`
  margin: 2vh;
  font-family: "GothamLight";
  font-size: 3.5vmin;
  color: white;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const LeftColumn = staggerStep({ transition: "slide-in-and-fade-from-left", order: 1 })(styled(Column)``);

export const RightColumn = staggerStep({ transition: "slide-in-and-fade-from-right", order: 1 })(styled(Column)``);

export const ColumnTitle = styled(Text)`
  margin-top: -3vh;
  font-family: "GothamBlack";
  font-size: 3vmin;
  text-align: center;
`;

export const ConfirmRow = styled.div`
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  pointer-events: ${(props) => (props.hide ? "none" : "inherit")};
`;

export const ColumnSubtitle = styled(Text)`
  margin-top: 1.8vh;
  font-family: "GothamLight";
  font-size: 2.5vmin;
  text-align: center;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

export const ConfirmButton = styled(StandardPillButton)``;

export const CodeTextFieldRow = styled.div`
  position: relative;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

export const FullCodeTextField = styled(CodeTextField)`
  width: 80%;
`;

export const ChoppedCodeTextField = styled(CodeTextField)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  width: 62%;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

export const CopyLinkButton = styled(Button)`
  background-color: #d8d8d8;
  border-radius: 0 2vh 2vh 0;
  border: 1px solid ${darkGreenColor};
  width: 18%;
  height: 12vh;
  min-height: 100%;
  max-height: 100%;
  font-family: "GothamBlack";
  font-size: 1.5vh;
  color: #7e7e7e;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  opacity: ${(props) => (props.hide ? 0 : 1)};

  &:before {
    content: url("${resource("images/Clipboard_Symbol_Grey.png")}");
    transform: scale3d(0.5, 0.5, 1);
  }
`;

export const GenerateCodeButton = styled(Button)`
  position: absolute;
  width: 80%;
  height: 100%;
  border-radius: 2vh;
  background-color: ${transparentize(0.8, darkGreenColor)};
  color: ${almostBlack};
  border: 1px solid ${darkGreenColor};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: ${(props) => (props.hide ? 0 : 1)};
  pointer-events: ${(props) => (props.hide ? "none" : "inherit")};
`;
