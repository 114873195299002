import PropTypes from "prop-types";
import { useCallback } from "react";
import { Clipboard, Strings, Toast, useData } from "ripple";
import {
  Content,
  DecorationsA,
  DecorationsB,
  DecorationsC,
  DecorationsD,
  Frame,
  SessionCodeButton,
  SessionCodeSymbol,
  SessionCodeText,
  SidebarRoot,
  Title,
} from "./styled";

const Sidebar = ({ children, ...rest }) => {
  const client = useData((data) => data.root);
  const code = localStorage.getItem("session-code");

  const onSessionCodeCopyClick = useCallback(() => {
    Clipboard.copy(`https://${location.hostname}${location.port ? `:${location.port}` : ""}/join/${code}`);
    Toast.info(Strings.localized("ClipboardCopySuccess").value);
  }, [code]);

  return (
    <SidebarRoot {...rest}>
      <Frame>
        <Title>{client.wantedText("Title")}</Title>
        <SessionCodeButton onClick={onSessionCodeCopyClick}>
          <SessionCodeText>
            {Strings.localized("SessionCode").value}: {code}
          </SessionCodeText>
          <SessionCodeSymbol />
        </SessionCodeButton>
        <DecorationsA />
        <DecorationsB />
        <DecorationsC />
        <DecorationsD />
        <Content>{children}</Content>
      </Frame>
    </SidebarRoot>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
};

export default Sidebar;
