import { Button, Droppable, Image, resource, Text } from "ripple";
import styled from "styled-components";
import { darkGreenColor, StandardPillButton } from "../../common";
import ElementBox from "../element-box";

export const GameSidebarContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ComponentArea = styled.div`
  background-color: ${darkGreenColor};
  width: 100%;
  height: 20vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ComponentTitle = styled(Text)`
  margin-top: 1.5vh;
  font-family: "Futura-Bold";
  font-size: 2vh;
  color: white;
  text-align: center;
  text-transform: uppercase;
`;

export const ComponentImage = styled(Image).attrs((props) => ({ scaling: "fit" }))`
  margin: 2vh;
  flex: 1;
`;

export const ComponentChangeLink = styled(Button)`
  margin-bottom: 1.5vh;
  font-family: "Futura-Bold";
  font-size: 1vh;
  color: white;
  text-decoration: underline;
  opacity: 0.3;
`;

export const Task = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6vh;

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "inherit")};
`;

export const TaskTitle = styled(Text)`
  margin: 2vh;
  margin-bottom: 0;
  font-family: "Futura-CondensedExtraBold";
  font-size: 2.8vh;
  color: white;
`;

export const TaskDescription = styled(Text)`
  margin: 1vh 2vh;
  font-size: 1vh;
  color: white;
`;

export const TaskItem = styled.div`
  margin: 0.6vh 2vh;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TaskItemDroppable = styled(Droppable)`
  width: 4vh;
  height: 4vh;
  pointer-events: ${(props) => (props.disabled ? "none" : "inherit")} !important;
`;

export const TaskItemIcon = styled(Image)`
  width: 4vh;
  min-width: 4vh;
  height: 4vh;
`;

export const TaskItemElementBox = styled(ElementBox)`
  width: 4vh;
  height: 4vh;
  font-size: 2vh;
`;

export const TaskItemText = styled(Text)`
  margin-left: 1.4vh;
  font-family: "GothamBold";
  color: white;
  font-size: 1.35vh;
`;

export const TaskItemCheckmark = styled(Image).attrs((props) => ({ src: resource("images/Check_Sidebar.svg") }))`
  margin-left: 1vh;
  width: 3vh;
  height: 3vh;
  transition: opacity 100ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const MapToggleButton = styled(StandardPillButton)`
  margin-top: auto;
  margin-bottom: 1vh;
  margin-left: 1vh;
  margin-right: 1vh;
  align-self: center;
`;
