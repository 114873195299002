import { Strings } from "ripple";

export default (language) => [
  {
    symbol: "Al",
    name: Strings.localized("Aluminium", language),
    countries: [
      Strings.localized("Jamaica", language),
      Strings.localized("Guyana", language),
      Strings.localized("Ghana", language),
    ],
    coords: ["E,6", "G,7", "I,6"],
  },
  {
    symbol: "As",
    name: Strings.localized("Arsenic", language),
    countries: [
      Strings.localized("Bolivia", language),
      Strings.localized("Greece", language),
      Strings.localized("England", language),
    ],
    coords: ["F,8", "K,4", "I,3"],
  },
  {
    symbol: "Be",
    name: Strings.localized("Beryllium", language),
    countries: [
      Strings.localized("Kazakhstan", language),
      Strings.localized("USA", language),
      Strings.localized("China", language),
    ],
    coords: ["M,4", "C,4", "P,5"],
  },
  {
    symbol: "B",
    name: Strings.localized("Boron", language),
    countries: [
      Strings.localized("USA", language),
      Strings.localized("Turkey", language),
      Strings.localized("Argentina", language),
    ],
    coords: ["C,4", "K,4", "F,9"],
  },
  {
    symbol: "Co",
    name: Strings.localized("Cobalt", language),
    countries: [
      Strings.localized("Cuba", language),
      Strings.localized("NewCaledonia", language),
      Strings.localized("DrCongo", language),
    ],
    coords: ["E,6", "S,8", "K,7"],
  },
  {
    symbol: "Cu",
    name: Strings.localized("Copper", language),
    countries: [
      Strings.localized("Canada", language),
      Strings.localized("Australia", language),
      Strings.localized("China", language),
    ],
    coords: ["C,3", "Q,8", "P,5"],
  },
  {
    symbol: "Dy",
    name: Strings.localized("Dysprosium", language),
    countries: [
      Strings.localized("China", language),
      Strings.localized("Malaysia", language),
      Strings.localized("Brazil", language),
    ],
    coords: ["P,5", "O,6", "F,7"],
  },
  {
    symbol: "In",
    name: Strings.localized("Indium", language),
    countries: [
      Strings.localized("Mexico", language),
      Strings.localized("Italy", language),
      Strings.localized("Germany", language),
    ],
    coords: ["D,6", "J,4", "J,4"],
  },
  {
    symbol: "Fe",
    name: Strings.localized("Iron", language),
    countries: [
      Strings.localized("Brazil", language),
      Strings.localized("Venezuela", language),
      Strings.localized("China", language),
    ],
    coords: ["F,7", "F,6", "P,5"],
  },
  {
    symbol: "Li",
    name: Strings.localized("Lithium", language),
    countries: [
      Strings.localized("Australia", language),
      Strings.localized("Canada", language),
      Strings.localized("Chile", language),
    ],
    coords: ["Q,8", "C,3", "E,9"],
  },
  {
    symbol: "Mg",
    name: Strings.localized("Magnesium", language),
    countries: [
      Strings.localized("China", language),
      Strings.localized("Brazil", language),
      Strings.localized("France", language),
    ],
    coords: ["O,5", "F,7", "J,4"],
  },
  {
    symbol: "Nd",
    name: Strings.localized("Neodymium", language),
    countries: [
      Strings.localized("Madagascar", language),
      Strings.localized("India", language),
      Strings.localized("SouthAfrica", language),
    ],
    coords: ["L,8", "N,6", "K,9"],
  },
  {
    symbol: "Ni",
    name: Strings.localized("Nickel", language),
    countries: [
      Strings.localized("Canada", language),
      Strings.localized("Russia", language),
      Strings.localized("Namibia", language),
    ],
    coords: ["C,3", "L,3", "J,8"],
  },
  {
    symbol: "Si",
    name: Strings.localized("Silicon", language),
    countries: [
      Strings.localized("USA", language),
      Strings.localized("Canada", language),
      Strings.localized("Brazil", language),
    ],
    coords: ["C,4", "C,3", "F,7"],
  },
  {
    symbol: "Ag",
    name: Strings.localized("Silver", language),
    countries: [
      Strings.localized("Poland", language),
      Strings.localized("Mexico", language),
      Strings.localized("Peru", language),
    ],
    coords: ["K,3", "D,6", "E,7"],
  },
  {
    symbol: "Sn",
    name: Strings.localized("Tin", language),
    countries: [
      Strings.localized("Bolivia", language),
      Strings.localized("Rwanda", language),
      Strings.localized("Spain", language),
    ],
    coords: ["F,8", "K,7", "I,4"],
  },
  {
    symbol: "Ti",
    name: Strings.localized("Titanium", language),
    countries: [
      Strings.localized("Ukraine", language),
      Strings.localized("Norway", language),
      Strings.localized("SouthAfrica", language),
    ],
    coords: ["K,4", "J,3", "K,9"],
  },
  {
    symbol: "Y",
    name: Strings.localized("Yttrium", language),
    countries: [
      Strings.localized("China", language),
      Strings.localized("Malaysia", language),
      Strings.localized("Brazil", language),
    ],
    coords: ["O,5", "O,7", "G,7"],
  },
  {
    symbol: "Zr",
    name: Strings.localized("Zirconium", language),
    countries: [
      Strings.localized("SouthAfrica", language),
      Strings.localized("Brazil", language),
      Strings.localized("Russia", language),
    ],
    coords: ["K,9", "G,7", "L,3"],
  },
];
