import { Text } from "ripple";
import styled from "styled-components";
import { greenColor } from "../../common";

export const PortraitBlockerContentRoot = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${greenColor};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled(Text)`
  font-size: 2vh;
  color: white;
  margin: 2vh;
  max-width: 60vw;
  text-align: center;
`;
