import Hammer from "@egjs/hammerjs";
import { Power3, TweenLite } from "gsap/all";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  createContext,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Classes from "../../../helpers/classes";
import Clipboard from "../../../helpers/clipboard";
import Maths from "../../../helpers/maths";
import Styles from "../../../helpers/styles";
import Toast from "../../../helpers/toast";
import Point from "../../../types/point";
import Rect from "../../../types/rect";
import Size from "../../../types/size";
import { Stagger } from "../stagger";

export const MapContentContext = createContext();

/**
 * The manipulatable "canvas" in a Map. You can either use this directly
 * (custom layout), or you can use one of the content sub-types:
 * Map.ImageContent, Map.VideoContent, etc.
 */
const MapContent = memo(
  forwardRef(
    (
      {
        className,
        style,
        children,
        width: contentWidth,
        height: contentHeight,
        maxZoom,
        interactive,
        staggerOptions,
        onZoom,
        ...rest
      },
      ref
    ) => {
      const [visible, setVisible] = useState(false);
      const rootRef = useRef(null);
      const handlersRef = useRef({});

      const translateXRef = useRef(0);
      const translateYRef = useRef(0);
      const baseScaleRef = useRef(1); // The initial scale that's applied to fill the map with this root
      const contentScaleRef = useRef(1); // The scaled induced by manipulation of the map

      const getScreenScale = useCallback(() => baseScaleRef.current * contentScaleRef.current, []);

      // ###### Map.Element Instances ######

      const updateElements = useCallback(() => {
        _.each(handlersRef.current, (h) => h.onScaleChange(contentScaleRef.current, getScreenScale()));
      }, [getScreenScale]);

      // ###### Calculations ######

      const calculateTranslationToCenterContentCoordsPoint = useCallback(
        (point) => {
          const contentSize = new Size(contentWidth, contentHeight);
          const frameCenterAtTargetViewportScale = point.scaledBy(getScreenScale());
          const contentSizeAtCurrentViewportScale = contentSize.scaledBy(getScreenScale());
          return {
            offsetX: -(frameCenterAtTargetViewportScale.x - contentSizeAtCurrentViewportScale.width / 2),
            offsetY: -(frameCenterAtTargetViewportScale.y - contentSizeAtCurrentViewportScale.height / 2),
          };
        },
        [contentHeight, contentWidth, getScreenScale]
      );

      const calculateScaleToFitContentCoordsFrame = useCallback(
        (frame) => {
          const mapElement = rootRef.current.parentNode;
          const viewport = new Rect(0, 0, mapElement.clientWidth, mapElement.clientHeight);
          const contentSize = new Size(contentWidth, contentHeight);

          const scaleToFitFrameInViewport = frame.size.scaleFactorToFitProportionallyIn(viewport.size);
          const scaleToFillViewportWithContent = contentSize.scaleFactorToFillProportionallyIn(viewport.size);
          return scaleToFitFrameInViewport / scaleToFillViewportWithContent;
        },
        [contentHeight, contentWidth]
      );

      // ###### Animation ######

      const animate = useCallback((duration, values, targetValues, onUpdate) => {
        TweenLite.to(values, duration, {
          ...targetValues,
          ease: Power3.easeInOut,
          onUpdate: () => onUpdate(values),
        });
      }, []);

      // ###### Transform Functions ######

      const getViewportRelativePositionForEvent = useCallback((event) => {
        const element = rootRef.current.parentNode;
        const bounds = element.getBoundingClientRect();
        return new Point(
          (event.center?.x ?? event.clientX) - bounds.left,
          (event.center?.y ?? event.clientY) - bounds.top
        );
      }, []);

      const clampPosition = useCallback(
        (point) => {
          const screenScale = getScreenScale();

          const zoomedSize = new Size(contentWidth, contentHeight).scaledBy(screenScale);

          const mapElement = rootRef.current.parentNode;
          const limitX = (zoomedSize.width / 2 - mapElement.clientWidth / 2) / screenScale;
          const limitY = (zoomedSize.height / 2 - mapElement.clientHeight / 2) / screenScale;

          return new Point(Maths.clamp(point.x, -limitX, limitX), Maths.clamp(point.y, -limitY, limitY));
        },
        [getScreenScale, contentHeight, contentWidth]
      );

      const translateBy = useCallback(
        (viewportDeltaX, viewportDeltaY) => {
          // Because we translate *after* scaling, we must compensate for the scale when calculating deltas
          const screenScale = getScreenScale();

          const { x, y } = clampPosition(
            new Point(
              translateXRef.current + viewportDeltaX / screenScale,
              translateYRef.current + viewportDeltaY / screenScale
            )
          );

          translateXRef.current = x;
          translateYRef.current = y;
        },
        [clampPosition, getScreenScale]
      );

      const setTranslation = useCallback((contentX, contentY) => {
        translateXRef.current = contentX;
        translateYRef.current = contentY;
      }, []);

      const resetTranslation = useCallback(() => {
        translateXRef.current = 0;
        translateYRef.current = 0;
      }, []);

      const setScale = useCallback(
        (wantedScale, aroundViewportPoint = null) => {
          const mapElement = rootRef.current.parentNode;
          aroundViewportPoint =
            aroundViewportPoint ?? new Point(mapElement.clientWidth / 2, mapElement.clientHeight / 2);

          const oldScale = contentScaleRef.current;
          const newScale = Maths.clamp(wantedScale, 1, maxZoom);

          // Set the new scale
          contentScaleRef.current = newScale;

          // Offset so that the zoom point stays centered
          // Explanation: https://stackoverflow.com/a/30410948/167983
          const scaleDifference = newScale - oldScale;
          const offsetFromCenterInContentCoordsX = (aroundViewportPoint.x - mapElement.clientWidth / 2) / oldScale;
          const offsetFromCenterInContentCoordsY = (aroundViewportPoint.y - mapElement.clientHeight / 2) / oldScale;
          translateBy(
            -(offsetFromCenterInContentCoordsX * scaleDifference),
            -(offsetFromCenterInContentCoordsY * scaleDifference)
          );

          onZoom?.(newScale);
        },
        [maxZoom, translateBy, onZoom]
      );

      const resetScale = useCallback(() => {
        contentScaleRef.current = 1;
      }, []);

      const updateTransform = useCallback(() => {
        // Bypass React state for maximum performance
        const screenScale = getScreenScale();
        rootRef.current.style.transform = `scale3d(${screenScale}, ${screenScale}, 1) translate3d(${translateXRef.current}px, ${translateYRef.current}px, 0)`;
        updateElements();
      }, [getScreenScale, updateElements]);

      // ###### Manipulation: Pan ######

      const panPositionRef = useRef(null);

      const onPanStart = useCallback(
        (event) => {
          if (!interactive) return;
          panPositionRef.current = getViewportRelativePositionForEvent(event);
        },
        [getViewportRelativePositionForEvent, interactive]
      );

      const onPanMove = useCallback(
        (event) => {
          if (!interactive) return;
          if (event.srcEvent.altKey) return;

          const position = getViewportRelativePositionForEvent(event);
          translateBy(position.x - panPositionRef.current.x, position.y - panPositionRef.current.y);
          updateTransform();

          panPositionRef.current = position;
        },
        [getViewportRelativePositionForEvent, translateBy, updateTransform, interactive]
      );

      // ###### Manipulation ######

      const pinchStartScaleRef = useRef(null);
      const pinchPositionRef = useRef(null);

      const onPinchStart = useCallback(
        (event) => {
          if (!interactive) return;
          pinchStartScaleRef.current = contentScaleRef.current;
          pinchPositionRef.current = getViewportRelativePositionForEvent(event);
        },
        [getViewportRelativePositionForEvent, interactive]
      );

      const onPinch = useCallback(
        (event) => {
          if (!interactive) return;
          event.srcEvent.stopPropagation();
          const position = getViewportRelativePositionForEvent(event);

          // Workaround for hammer.js pinchstart sometimes not being fired
          if (!pinchPositionRef.current) return;

          translateBy(position.x - pinchPositionRef.current.x, position.y - pinchPositionRef.current.y);
          setScale(pinchStartScaleRef.current * event.scale, position);
          updateTransform();

          pinchPositionRef.current = position;
        },
        [getViewportRelativePositionForEvent, setScale, translateBy, updateTransform, interactive]
      );

      const onWheel = useCallback(
        (event) => {
          if (!interactive) return;
          const position = getViewportRelativePositionForEvent(event);
          setScale(contentScaleRef.current + event.deltaY / 1000, position);
          updateTransform();
        },
        [getViewportRelativePositionForEvent, setScale, updateTransform, interactive]
      );

      const onTap = useCallback((event) => {
        if (!event.srcEvent.altKey) return;
        event.srcEvent.stopPropagation();

        const bounds = rootRef.current.getBoundingClientRect();
        const touchX = event.srcEvent.clientX - bounds.left;
        const touchY = event.srcEvent.clientY - bounds.top;
        const coordinates = { x: touchX / bounds.width, y: touchY / bounds.height };

        Clipboard.copy(JSON.stringify(coordinates));
        Toast.info("Coordinates copied to clipboard!");
      }, []);

      useEffect(() => {
        const mapElement = rootRef.current.parentNode;
        const hammer = new Hammer(mapElement, { domEvents: true });

        const pinch = new Hammer.Pinch();
        const pan = new Hammer.Pan();

        pan.recognizeWith(pinch);
        pinch.recognizeWith(pan);

        hammer.add(pan);
        hammer.on("panstart", onPanStart);
        hammer.on("panmove", onPanMove);

        hammer.add(pinch);
        hammer.on("pinchstart", onPinchStart);
        hammer.on("pinch", onPinch);

        hammer.on("tap", onTap);

        mapElement.addEventListener("mousewheel", onWheel);

        return () => {
          hammer.destroy();
          mapElement.removeEventListener("mousewheel", onWheel);
        };
      }, [onPanMove, onPanStart, onPinch, onPinchStart, onTap, onWheel]);

      // ###### API #######

      const center = useCallback(
        (normalizedPoint, animation = { animate: true, duration: 500 }) => {
          const point = normalizedPoint.convertNormalizedToAbsoluteIn(new Rect(0, 0, contentWidth, contentHeight));
          const screenScale = getScreenScale();
          const { offsetX, offsetY } = calculateTranslationToCenterContentCoordsPoint(point);

          animate(
            animation.animate ? animation.duration / 1000 : 0,
            {
              offsetX: translateXRef.current * screenScale,
              offsetY: translateYRef.current * screenScale,
            },
            { offsetX, offsetY },
            (values) => {
              setTranslation(values.offsetX / screenScale, values.offsetY / screenScale);
              updateTransform();
            }
          );
        },
        [
          animate,
          calculateTranslationToCenterContentCoordsPoint,
          contentHeight,
          contentWidth,
          getScreenScale,
          setTranslation,
          updateTransform,
        ]
      );

      const fit = useCallback(
        (normalizedFrame, animation = { animate: true, duration: 500 }) => {
          const frame = normalizedFrame.convertNormalizedToAbsoluteIn(new Rect(0, 0, contentWidth, contentHeight));
          const { offsetX: targetOffsetX, offsetY: targetOffsetY } = calculateTranslationToCenterContentCoordsPoint(
            frame.center
          );
          const targetScale = calculateScaleToFitContentCoordsFrame(frame);
          const screenScale = getScreenScale();

          animate(
            animation.animate ? animation.duration / 1000 : 0,
            {
              offsetX: translateXRef.current * screenScale,
              offsetY: translateYRef.current * screenScale,
              scale: contentScaleRef.current,
            },
            {
              offsetX: targetOffsetX,
              offsetY: targetOffsetY,
              scale: targetScale,
            },
            (values) => {
              setTranslation(values.offsetX / screenScale, values.offsetY / screenScale);
              setScale(values.scale);
              updateTransform();
            }
          );
        },
        [
          animate,
          calculateScaleToFitContentCoordsFrame,
          calculateTranslationToCenterContentCoordsPoint,
          getScreenScale,
          setScale,
          setTranslation,
          updateTransform,
          contentWidth,
          contentHeight,
        ]
      );

      useImperativeHandle(ref, () => ({ fit, center }), [center, fit]);

      // ###### Final Setup ######

      // Set the base scale to fill the map
      useEffect(() => {
        const mapElement = rootRef.current.parentNode;

        const viewportSize = new Size(mapElement.clientWidth, mapElement.clientHeight);
        const rootSize = new Size(contentWidth, contentHeight);

        baseScaleRef.current = rootSize.scaleFactorToFillProportionallyIn(viewportSize);
        updateTransform();
        updateElements();

        setVisible(true);
      }, [resetScale, resetTranslation, updateTransform, contentWidth, contentHeight, updateElements]);

      // ###### Content Context ######

      const registerContent = useCallback((id, handlers) => (handlersRef.current[id] = handlers), []);
      const unregisterContent = useCallback((id) => delete handlersRef.current[id], []);

      const contentContext = useMemo(
        () => ({
          registerContent,
          unregisterContent,
        }),
        [registerContent, unregisterContent]
      );

      // ###### Render ######

      return (
        <div
          {...rest}
          ref={rootRef}
          className={Classes.build("map-content", className)}
          style={Styles.merge(style, {
            width: contentWidth,
            height: contentHeight,
            visibility: visible ? "visible" : "hidden",
          })}
        >
          {/* Wrapping the content in a Stagger enables automatic staggering
          of any stagger-enabled components in the children or their descendants, even if the
          map content is added to the DOM after the page stagger runs (for example, after the image loads). */}
          <Stagger options={staggerOptions}>
            <MapContentContext.Provider value={contentContext}>{children}</MapContentContext.Provider>
          </Stagger>
        </div>
      );
    }
  )
);
MapContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  maxZoom: PropTypes.number,
  interactive: PropTypes.bool, // Set to false to disable pan and zoom (does not affect interactivity of children)
  staggerOptions: PropTypes.object,
  onZoom: PropTypes.func,
};

MapContent.defaultProps = {
  maxZoom: 5,
};

export default MapContent;
