import { useData } from "ripple";
import useFiltersState from "../../hooks/use-filters-state";
import ResourceMap from "../resource-map";
import { FiltersContentRoot, Title } from "./styled";

const FiltersContent = ({ ...rest }) => {
  const client = useData((data) => data.root);

  const { enabledComponents, enabledPositionedElements, forceEnabled } = useFiltersState();

  return (
    <FiltersContentRoot {...rest}>
      <Title>{client.wantedText("FiltersTitle")}</Title>
      <ResourceMap
        drawNodePins={[...enabledComponents, ...enabledPositionedElements]}
        drawAssembly={forceEnabled.assembly === undefined ? true : forceEnabled.assembly}
        drawStore={forceEnabled.store === undefined ? true : forceEnabled.store}
      />
    </FiltersContentRoot>
  );
};

FiltersContent.propTypes = {};

export default FiltersContent;
