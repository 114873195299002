import _ from "lodash";
import { includesElement } from "../../logic";
import useCompleted from "./use-completed";

const useTask2Completed = (component) => {
  const completed = useCompleted();
  if (component === null) return false;
  return _.every(component.children, (element) => includesElement(completed, element));
};

export default useTask2Completed;
