import styled from "styled-components";

export const PortraitBlockerRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  display: none;
  pointer-events: none;
  @media only screen and (max-aspect-ratio: 399/300) {
    display: block;
    pointer-events: inherit;
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
