import { useCallback } from "react";
import { useData } from "ripple";
import useCompleted from "./use-completed";
import useStore from "./use-store";

const useFiltersState = () => {
  const client = useData((data) => data.root);

  const components = client.children;
  const elements = components.flatMap((c) => c.children);
  const positionedElements = elements.flatMap((c) => c.children);

  const completed = useCompleted();

  const forceEnabled = useStore((state) => state.forceEnabled);
  const setForceEnabled = useStore((state) => state.setForceEnabled);

  const disableAllComponents = useStore((state) => state.disableAllComponents);
  const setDisableAllComponents = useStore((state) => state.setDisableAllComponents);
  const enabledComponents = disableAllComponents
    ? []
    : components.filter((c) => forceEnabled[c.id] ?? completed.includes(c.id));

  const disableAllElements = useStore((state) => state.disableAllElements);
  const setDisableAllElements = useStore((state) => state.setDisableAllElements);
  const enabledPositionedElements = disableAllElements
    ? []
    : positionedElements.filter((pe) => forceEnabled[pe.id] ?? completed.includes(pe.id));

  const forceEnable = useCallback((key, value) => setForceEnabled(key, value), [setForceEnabled]);

  return {
    enabledComponents,
    enabledPositionedElements,
    forceEnabled,
    forceEnable,
    disableAllComponents,
    setDisableAllComponents,
    disableAllElements,
    setDisableAllElements,
  };
};

export default useFiltersState;
