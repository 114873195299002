import { Button, Image, resource, Scroller, Text } from "ripple";
import styled from "styled-components";
import { darkGreenColor, greenColor } from "../../common";
import ElementBox from "../element-box";

export const FiltersSidebarContentRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding-top: 0.5vh;
  background-color: ${darkGreenColor};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Splitter = styled.div`
  margin: 2vh;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.15);
`;

export const ItemsScroller = styled(Scroller).attrs((props) => ({
  fadeRatio: 0.01,
  endFadeRatio: 0.05,
  scrollbarInset: 10,
}))`
  height: 30vh;

  .scroller-content {
    padding-top: 0.4vh;
  }

  .scroller-thumb {
    background-color: ${greenColor};
    width: 0.4vh;
  }
`;

export const CheckRow = styled(Button)`
  margin-bottom: 0.5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.disabled {
    opacity: 0.2;
  }
`;

export const CheckRowIcon = styled(Image)`
  margin-left: 2vh;
  width: 4vh;
  min-width: 4vh;
  height: 4vh;
  min-height: 4vh;
`;

export const CheckRowSectionTitle = styled(Text)`
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 1.2vh;
  margin-right: 1vh;
  font-size: 1.8vh;
  color: white;
`;

export const CheckRowTitle = styled(Text)`
  margin-left: 1.2vh;
  margin-right: 1.2vh;
  font-size: 1.5vh;
  color: white;
`;

export const CheckRowBox = styled.div`
  margin-left: auto;
  margin-right: 4vh;
  width: 2.2vh;
  min-width: 2.2vh;
  height: 2.2vh;
  min-height: 2.2vh;
  border: 0.3vh solid ${greenColor};
  border-radius: 0.5vh;
`;

export const CheckRowMark = styled(Image).attrs((props) => ({ src: resource("images/filters_check.svg") }))`
  transform: scale3d(1.7, 1.7, 1) translate3d(0.3vh, -0.4vh, 0);
`;

export const SizedElementBox = styled(ElementBox)`
  margin-left: 2vh;
  width: 4vh;
  height: 4vh;
  font-size: 2vh;
`;
