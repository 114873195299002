import _ from "lodash";
import { useCallback } from "react";
import { useLocation, useParams } from "react-router";
import { Navigator, Strings, useData } from "ripple";
import { includesElement } from "../../../logic";
import { darkGreenColor, Link } from "../../common";
import useCompleted from "../../hooks/use-completed";
import useTask1Completed from "../../hooks/use-task1-completed";
import {
  ComponentArea,
  ComponentChangeLink,
  ComponentImage,
  ComponentTitle,
  GameSidebarContentRoot,
  MapToggleButton,
  Task,
  TaskDescription,
  TaskItem,
  TaskItemCheckmark,
  TaskItemDroppable,
  TaskItemElementBox,
  TaskItemIcon,
  TaskItemText,
  TaskTitle,
} from "./styled";

const GameSidebarContent = ({ ...rest }) => {
  const { id } = useParams();
  const location = useLocation();
  const component = useData((data) => data.requiredNode(id));

  const completed = useCompleted();
  const isTask1Completed = useTask1Completed(component);

  // https://stackoverflow.com/a/37511463/167983
  const elements = _.orderBy(component.children, (e) =>
    e
      .wantedText("Title")
      .value.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  );

  const isTeamPage = location.pathname.endsWith("team");
  const onMapToggleButtonClick = useCallback(() => {
    if (isTeamPage) {
      Navigator.navigate({ node: component, type: "task" });
    } else {
      Navigator.navigate({ node: component, type: "team" });
    }
  }, [component, isTeamPage]);

  return (
    <GameSidebarContentRoot {...rest}>
      {localStorage.getItem("specmode") && (
        <Link navigate={{ type: "filters" }}>{Strings.localized("FinalMapLink")}</Link>
      )}
      <ComponentArea>
        <ComponentTitle>{component.wantedText("Title")}</ComponentTitle>
        <ComponentImage src={component.wantedMedia("ComponentImage", "ComponentImage")} />
        <ComponentChangeLink navigate={{ type: "components" }}>
          {Strings.localized("ComponentChangeLink")}
        </ComponentChangeLink>
      </ComponentArea>
      <Task>
        <TaskTitle>{component.wantedText("SidebarTask1Title")}</TaskTitle>
        <TaskDescription>{component.wantedText("SidebarTask1Description")}</TaskDescription>
        <TaskItem>
          <TaskItemDroppable id={component} disabled={isTask1Completed}>
            <TaskItemIcon src={component.wantedMedia("FabricationIcon", "Icon")} />
          </TaskItemDroppable>
          <TaskItemText>{component.wantedText("FabricationTitle")}</TaskItemText>
          <TaskItemCheckmark show={isTask1Completed} />
        </TaskItem>
      </Task>
      <Task disabled={!isTask1Completed}>
        <TaskTitle>{component.wantedText("SidebarTask2Title")}</TaskTitle>
        <TaskDescription>{component.wantedText("SidebarTask2Description")}</TaskDescription>
        {elements.map((element) => (
          <TaskItem key={element.id}>
            <TaskItemDroppable id={element} disabled={includesElement(completed, element)}>
              <TaskItemElementBox
                foreground={darkGreenColor}
                background="white"
                symbol={element.wantedText("Symbol").value}
              />
            </TaskItemDroppable>
            <TaskItemText>{element.wantedText("Title")}</TaskItemText>
            <TaskItemCheckmark show={includesElement(completed, element)} />
          </TaskItem>
        ))}
      </Task>
      <MapToggleButton onClick={onMapToggleButtonClick}>
        {Strings.localized(isTeamPage ? "GoToMap" : "GoToTeam")}
      </MapToggleButton>
    </GameSidebarContentRoot>
  );
};

GameSidebarContent.propTypes = {};

export default GameSidebarContent;
