import { Grid, Image } from "ripple";
import styled from "styled-components";
import MapDropzone from "../map-dropzone";

export const ResourceMapRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const MapImage = styled(Image).attrs((props) => ({ scaling: "fit" }))``;

export const Dropzones = styled(Grid).attrs((props) => ({ layout: Grid.layout.fixedColumns(19) }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  margin-left: 1.05%;
  margin-top: 2.2%;

  .grid-row {
    padding: 0;
    margin: 0;
    height: 9%;
  }
`;

export const ResourceMapDropzone = styled(MapDropzone)`
  width: 5.163398692810457%;
`;

export const Visuals = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const MapPin = styled(Image).attrs((props) => ({ fadeIn: "always" }))`
  position: absolute;
  top: ${(props) => props.frame?.y * 100}%;
  left: ${(props) => props.frame?.x * 100}%;
  width: ${(props) => props.frame?.width * 100}%;
  height: ${(props) => props.frame?.height * 100}%;
`;
