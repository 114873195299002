import _ from "lodash";
import { useParams } from "react-router";
import { Paragraphs, resource, Strings, useData, useLanguage } from "ripple";
import { includesElement } from "../../../logic";
import useCompleted from "../../hooks/use-completed";
import useTask1Completed from "../../hooks/use-task1-completed";
import getElementDescriptors from "./get-element-descriptors";
import {
  Content,
  Description,
  ElementColumn,
  ElementDescription,
  ElementIcon,
  ElementRow,
  ElementsTable,
  ElementTitle,
  InfoLocationImage,
  NumberedList,
  NumberedListItem,
  NumberedListMarker,
  NumberedListText,
  TableElementBox,
  TableElementCellContent,
  TaskBox,
  TaskHeader,
  TaskHeaderCheck,
  TaskHeaderImage,
  TaskHeaderText,
  TeamContentRootScroller,
  Title,
} from "./styled";

const renderTextWithNumberedBullets = (text) => {
  const texts = text.split("<ol>").flatMap((t) => t.split("</ol>"));
  return texts.map((t, rootIndex) => {
    if (t.startsWith("<li>")) {
      const items = t
        .split("<li>")
        .flatMap((t) => t.split("</li>"))
        .filter((t) => t !== "");
      return (
        <NumberedList>
          {items.map((item, index) => (
            <NumberedListItem key={item + index}>
              <NumberedListMarker>{index + 1}</NumberedListMarker>
              <NumberedListText>{item}</NumberedListText>
            </NumberedListItem>
          ))}
        </NumberedList>
      );
    } else {
      return <Paragraphs key={text + rootIndex}>{t}</Paragraphs>;
    }
  });
};

const InfoContent = ({ ...rest }) => {
  const { id } = useParams();
  const component = useData((data) => data.requiredNode(id));
  const language = useLanguage();

  const completed = useCompleted();
  const isTask1Completed = useTask1Completed(component);
  const isTask2Completed = _.every(component.children, (element) => includesElement(completed, element));

  const renderElement = ({ symbol, name, countries, coords }, index) => {
    const className = index % 2 === 0 ? "darker" : undefined;
    return (
      <>
        <tr className={className}>
          <td rowSpan={3} className="col1 element">
            <TableElementCellContent>
              <TableElementBox symbol={symbol} />
              {name.value}
            </TableElementCellContent>
          </td>
          <td className="col2 top-row">{countries[0].value}</td>
          <td className="col3 top-row">{coords[0]}</td>
        </tr>
        <tr className={className}>
          <td className="col2">{countries[1].value}</td>
          <td className="col3">{coords[1]}</td>
        </tr>
        <tr className={className}>
          <td className="col2 bottom-row">{countries[2].value}</td>
          <td className="col3 bottom-row">{coords[2]}</td>
        </tr>
      </>
    );
  };

  return (
    <TeamContentRootScroller {...rest} id="team-page-scroller">
      <Content>
        <Title>{component.wantedText("InfoTitle")}</Title>
        <Description>{component.wantedText("InfoDescription")}</Description>

        <TaskHeader>
          <TaskHeaderImage src={resource("images/Boat_Icon_Red.svg")} />
          <TaskHeaderText>{component.wantedText("InfoTask1Title")}</TaskHeaderText>
          <TaskHeaderCheck show={isTask1Completed} />
        </TaskHeader>
        <TaskBox>{renderTextWithNumberedBullets(component.wantedText("InfoTask1Box").value)}</TaskBox>

        <Description>{component.wantedText("InfoLocation")}</Description>
        <InfoLocationImage />

        <TaskHeader>
          <TaskHeaderImage src={resource("images/Boat_Icon_Red.svg")} />
          <TaskHeaderText>{component.wantedText("InfoTask2Title")}</TaskHeaderText>
          <TaskHeaderCheck show={isTask2Completed} />
        </TaskHeader>
        <Description style={{ marginBottom: "2.5vh" }}>{component.wantedText("InfoTask2Description")}</Description>
        {component.children.map((element) => (
          <ElementRow key={element.id}>
            <ElementIcon symbol={element.wantedText("Symbol")} foreground={component.wantedText("Color").value} />
            <ElementColumn>
              <ElementTitle>{element.wantedText("Title")}</ElementTitle>
              <ElementDescription>{element.wantedText("Description")}</ElementDescription>
            </ElementColumn>
          </ElementRow>
        ))}
        <TaskBox>{renderTextWithNumberedBullets(component.wantedText("InfoTask2Box").value)}</TaskBox>

        <ElementsTable>
          <tbody>
            <tr>
              <th className="col1">{Strings.localized("TableElement").value}</th>
              <th className="col2">{Strings.localized("TableMiningLocation").value}</th>
              <th className="col3">{Strings.localized("TableMapCoordinates").value}</th>
            </tr>
            {_.orderBy(getElementDescriptors(language), (e) =>
              e.name.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ).map(renderElement)}
          </tbody>
        </ElementsTable>
      </Content>
    </TeamContentRootScroller>
  );
};

InfoContent.propTypes = {};

export default InfoContent;
