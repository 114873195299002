import { Howl, Howler } from "howler";
import Sequencer from "sequencer.js";
import { v4 as uuidv4 } from "uuid";
import Config from "../helpers/config";
import MediaInfo from "../logic/info/media-info";
import Log from "./log";

const tracks = {};
const backgrounds = {};
const discretes = {};

// Preload Howler.ctx (a Web Audio context), which can be useful in some
// scenarios (such as setting up a waveform analyser before playing any sounds).
// https://stackoverflow.com/a/46710696/167983
Howler.mute(false);
Howler.volume(1);

// Workaround for a super-weird issue where audio analyser data values would all
// be `-Infinity` if no Howl was created prior to creating the audio analyser.
// Doing this as early as possible in case this affects other aspects of Howler.
new Howl({ src: [null] });

// NOTE: "context" is the general term for a named group of sounds. Can be
// either a track, a background track or a discrete sounds group.

const defaultOptions = {
  muted: false,
  volume: 1, // The default volume for sounds played. Overridable on `play()`.
  volumeScale: 1, // Should generally not be overridden on `play()` but *can be* to ignore the parent volume scales.
};

// Emitting this way is very important to avoid very subtle and hard to debug
// recursion which may otherwise occur when starting a sound from one of the
// play callbacks (onStart, onFinish). This occurred, for example, when waiting
// for a sound to finish using an RxJs Observable which then (indirectly,
// through a long chain of calls) synchronously started another sound. The
// resulting recursion caused the onFinish callback to be called twice in
// certain conditions (and may also have messed up internal state in other
// subtle ways), which caused all kinds of mibehaviour. Just run callbacks with
// this, will you!!!
function callAsync(func) {
  setTimeout(func, 0);
}

function mergeOptions(name, contextOptions, optionOverrides) {
  // Start with the default options and merge in the values provided for the
  // group in the config (if any). Then, merge in the options that were
  // explicitly provided for the context (if any), then merge in the option
  // overrides.
  const groupOptions = Config.audio.groups[name];
  const mergedWithoutOverrides = Object.assign({}, defaultOptions, groupOptions, contextOptions);
  const mergedWithOverrides = Object.assign({}, mergedWithoutOverrides, optionOverrides);

  // Special case for the mute config: mute cannot be overridden
  if (mergedWithoutOverrides && mergedWithoutOverrides.muted) mergedWithOverrides.muted = true; // Group mute config overrides any specified value
  if (Config.audio.global.muted) mergedWithOverrides.muted = true; // Global mute config overrides all!

  return mergedWithOverrides;
}

function computeVolume(options) {
  return Config.audio.global.volumeScale * options.volumeScale * options.volume;
}

function computeStereo(options) {
  return options.stereo || 0;
}

function createHowl(path, howlOptionOverrides) {
  const defaultHowlOptions = {
    src: [path],
    ext: ["mp3"],
    format: "mp3", // Necessary when a URL does not have a file extension
    loop: false,
    preload: true,
  };
  return new Howl(Object.assign({}, defaultHowlOptions, howlOptionOverrides));
}

function updateOrCreateTrack(name, collection, options) {
  let track = collection[name];
  if (!track) {
    // eslint-disable-next-line no-use-before-define
    track = new Track(name);
    collection[name] = track;
  }

  track.configure(options);

  return track;
}

function updateOrCreateDiscrete(name, options) {
  let discrete = discretes[name];
  if (!discrete) {
    // eslint-disable-next-line no-use-before-define
    discrete = new Discrete(name);
    discretes[name] = discrete;
  }

  discrete.configure(options);

  return discrete;
}

function getSoundPath(sound) {
  return sound instanceof MediaInfo ? sound.url : sound;
}

class Handlers {
  constructor(options) {
    this.options = options;
  }

  // Called when the sound fails to load or play
  onError() {
    if (this.options.onError) callAsync(this.options.onError);
  }

  // Called when the sound starts playing
  onStart() {
    if (this.options.onStart) callAsync(this.options.onStart);
  }

  // Called when the sound finishes playing, either because it ended or because it was stopped
  onFinish() {
    if (this.options.onFinish) callAsync(this.options.onFinish);
  }
}

class Sound {
  constructor(path, options, howlOptions) {
    this.id = uuidv4();
    this.path = path;
    this.howl = createHowl(path, howlOptions);
    this.options = options;
    this.handlers = new Handlers(this.options);
  }
}

class Track {
  defaults = {
    fadeDuration: 1000,

    // Whether to loop individual sounds indefinitely after they finish playing.
    // NOTE: If you want to set this to true, you'll most probably want to use a
    // background track instead.
    loopSounds: false,

    // Whether to avoid starting a new sound (and keep the current sound
    // playing) if the new sound's path is the same as the current one. With
    // this on, asking a track to play a sound with the same path as the one
    // that's currently playing becomes a no-op. This is enabled by default
    // for background tracks.
    continueIfSamePath: false,
  };

  sequencer = new Sequencer();
  sound = null;
  options = {};

  constructor(name) {
    this.name = name;
    this.resetConfiguration();
  }

  get isPlaying() {
    return this.sound !== null;
  }

  configure = (options) => {
    Object.assign(this.options, options);
  };

  resetConfiguration = () => {
    this.options = Object.assign({}, this.defaults);
  };

  play = (sound, optionOverrides = {}) => {
    const path = getSoundPath(sound);

    const options = mergeOptions(this.name, this.options, optionOverrides);
    const samePathAsCurrentSound = path === (this.sound && this.sound.path);

    if (options.muted || (options.continueIfSamePath && samePathAsCurrentSound)) return;

    if (!path) {
      const message = `Audio (${this.name}): Can't play sound because provided path is null or undefined`;
      Log.warn(message);
      new Handlers(options).onError(message);
      return;
    }

    const howlOptions = {
      volume: computeVolume(options),
      stereo: computeStereo(options),
      loop: this.options.loopSounds /* Can't be overridden! */,
    };

    this.sequencer.doWaitForRelease((release) => {
      const newSound = new Sound(path, options, howlOptions);

      Log.debug(`Audio (${this.name}): Playing sound '${path}'`);

      newSound.howl.once("loaderror", () => {
        const message = `Audio (${this.name}): Failed to load sound from '${path}'`;
        Log.warn(message);
        newSound.handlers.onError(message);
        release();
      });

      newSound.howl.once("playerror", () => {
        const message = `Audio (${this.name}): Failed to play sound loaded from '${path}'`;
        Log.error(message);
        newSound.handlers.onError(message);
        release();
      });

      newSound.howl.once("end", () => {
        newSound.handlers.onFinish();

        // Since setting the sound to null is the way we indicate that no sound
        // is playing AND because the "end" event is called when a sound ends
        // *even for looping sounds*, we keep the sound around when `loopSounds`
        // is set to true, which (when the time comes) triggers our crossfading
        // logic instead of the "no sound is playing" logic.
        if (this.options.loopSounds) newSound.handlers.onStart();
        if (!this.options.loopSounds) this.sound = null;
      });

      const postLoadActions = () => {
        newSound.howl.play();
        newSound.handlers.onStart();

        if (this.sound === null) {
          this.sound = newSound; // No sound was playing, replace instantly
          release(); // Instantly release because we aren't fading anything (no need to wait)
        } else {
          // A sound is already playing, crossfade between the old one and the new one
          const oldSound = this.sound;

          // Fade out the old sound
          oldSound.howl.off("end"); // Cancel the old sound's event handler (we'll handle it from here!)
          oldSound.howl.fade(oldSound.howl.volume(), 0, options.fadeDuration);

          // Fade in the new sound
          newSound.howl.once("fade", () => {
            oldSound.handlers.onFinish();

            this.sound = newSound;
            release();
          });
          newSound.howl.fade(0, howlOptions.volume, options.fadeDuration);
        }
      };

      if (newSound.howl.state() === "loaded") {
        postLoadActions();
      } else {
        newSound.howl.once("load", postLoadActions);
      }
    });
  };

  stop = (optionOverrides = {}) => {
    const options = mergeOptions(this.name, this.options, optionOverrides);

    this.sequencer.doWaitForRelease((release) => {
      const oldSound = this.sound;
      if (!this.sound) {
        release();
        return;
      }

      oldSound.howl.once("fade", () => {
        oldSound.handlers.onFinish();
        oldSound.howl.off("end");
        oldSound.howl.off("fade");
        oldSound.howl.stop();
        this.sound = null;
        release();
      });
      oldSound.howl.fade(oldSound.howl.volume(), 0, options.fadeDuration);
    });
  };
}

class Discrete {
  defaults = {};
  options = {};

  constructor(name) {
    this.name = name;
    this.resetConfiguration();
  }

  configure = (options) => {
    Object.assign(this.options, options);
  };

  resetConfiguration = () => {
    this.options = Object.assign({}, this.defaults);
  };

  play = (sound, optionOverrides = {}) => {
    const path = getSoundPath(sound);

    const options = mergeOptions(this.name, this.options, optionOverrides);
    if (options.muted) return;

    Log.debug(`Audio (${this.name}): Playing sound '${path}'`);

    const howlOptions = {
      volume: computeVolume(options),
      stereo: computeStereo(options),
    };

    const howl = createHowl(path, howlOptions);
    const handlers = new Handlers(options);

    howl.once("loaderror", () => handlers.onError(`Audio (${this.name}): Failed to load sound from '${path}'`));
    howl.once("play", () => handlers.onStart());
    howl.once("end", () => handlers.onFinish());

    howl.play();
  };
}

/**
 * Exposes various ways to play audio in a simple manner.
 *
 * `Audio.background` and `Audio.track` have the exact same API except that
 * `Audio.background` loops a sound indefinitely until it's replaced by another.
 *
 * Options:
 *
 * - `volume`: The (default) volume of sounds. Affected by `volumeScale`.
 * - `volumeScale`: Scales the volume of all sounds played in this context by the provided fraction.
 * - `fadeDuration`: The amount of time (in milliseconds) for fades and crossfades.
 * - `onStart`: Called when the sound actually starts playing (after it's been buffered).
 * - `onFinish`: Called when the sound finishes playing (either because it was stopped or because it has ended).
 *
 * Play sounds in a single track in which sounds cannot overlap (except during crossfade).
 * In a background track, a sound loops until it is replaced by another sound.
 * ```
 * const ambience = Audio.background("ambience", { volume: 0.5, fadeDuration: 5000 }); // Configure the background track inline
 * ambience.play("nature1.mp3"); // Play and loops nature1.mp3 until another `play()` is requested
 * ambience.play("nature2.mp3", { volume: 1 }); // Crossfade to nature2.mp3 at full volume
 * Audio.background("ambience").stop({ fadeDuration: 0 }); // Stop the audio instantly by overriding the default fadeDuration
 * ```
 *
 * Play sounds in a single track in which sounds cannot overlap except during crossfade. Sounds play once.
 * ```
 * const voiceover = Audio.track("voiceover");
 * voiceover.configure({ volume: 0.5 }); // We can configure the track after obtaining and storing it
 * voiceover.play("voiceover1.mp3", { volume: 1 }); // Play voiceover1.mp3 *once* at full volume
 * voiceover.play("voiceover2.mp3"); // Play voiceover2.mp3 *once*, crossfading from voiceover1.mp3 if it's not done playing
 * ```
 *
 * Play discrete sounds (can overlap, cannot be stopped):
 * ```
 * const effects = Audio.discrete("effects");
 * effects.configure({ volume: 0.5 });
 * effects.play("click1.mp3", { volume: 0.5 }); // Plays a sound at half volume
 * effects.play("click2.mp3", { volume: 1 }); // Plays a sound at full volume
 * effects.play("click3.mp3"); // Plays a sound with the default volume
 * ```
 */
class Audio {
  _analyzerApi = null;

  static track(name, options) {
    return updateOrCreateTrack(name, tracks, options);
  }

  static background(name, options) {
    return updateOrCreateTrack(
      name,
      backgrounds,
      Object.assign({}, options, { loopSounds: true, continueIfAlreadyPlaying: true })
    );
  }

  static discrete(name, options) {
    return updateOrCreateDiscrete(name, options);
  }

  /**
   * Usage:
   * const analyzer = Audio.analyzer;
   * const currentFrequencies = analyzer.getCurrentFrequencyData(); // Call as often as needed
   */
  static get analyzer() {
    if (Audio._analyzerApi) return Audio._analyzerApi;

    const analyser = Howler.ctx.createAnalyser(); // "Analyser" Seems like a spelling error in the Web Audio API!
    analyser.fftSize = 256;
    Howler.masterGain.connect(analyser);
    analyser.connect(Howler.ctx.destination);

    const frequencyBinCount = analyser.frequencyBinCount;
    const data = new Float32Array(frequencyBinCount);

    Audio._analyzerApi = class {
      /** Call this as often as required to obtain current audio frequency data
       * (contains information about all sounds playing in the Howler Web Audio
       * context). */
      static getCurrentFrequencyData() {
        analyser.getFloatFrequencyData(data);
        return data;
      }
    };

    return Audio._analyzerApi;
  }
}

export default Audio;
