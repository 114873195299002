import _ from "lodash";
import { useCallback, useMemo } from "react";
import { resource, Strings, useData } from "ripple";
import { includesElement } from "../../../logic";
import { Link, Links } from "../../common";
import useCompleted from "../../hooks/use-completed";
import useFiltersState from "../../hooks/use-filters-state";
import useStore from "../../hooks/use-store";
import {
  CheckRow,
  CheckRowBox,
  CheckRowIcon,
  CheckRowMark,
  CheckRowSectionTitle,
  CheckRowTitle,
  Content,
  FiltersSidebarContentRoot,
  ItemsScroller,
  Section,
  SizedElementBox,
  Splitter,
} from "./styled";

const FiltersSidebarContent = ({ ...rest }) => {
  const client = useData((data) => data.root);

  const allComponents = client.children;
  const allElements = allComponents.flatMap((c) => c.children);
  const uniqueElements = _.orderBy(
    _.uniqBy(allElements, (e) => e.wantedText("Symbol").value),
    (e) =>
      e
        .wantedText("Title")
        .value.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
  );

  const actuallyCompleted = useCompleted();
  const allAsIfCompleted = useMemo(
    () => [
      ...allComponents.map((c) => c.id),
      ...allElements
        .flatMap((e) => {
          // Only one positioned element, either the one that has been chosen by students or the first one in the list
          return _.find(e.children, (pe) => actuallyCompleted.includes(pe.id)) ?? [e.children[0]];
        })
        .map((pe) => pe.id),
    ],
    [actuallyCompleted, allComponents, allElements]
  );

  const resetForceEnabled = useStore((state) => state.resetForceEnabled);
  const filtersDataSource = useStore((state) => state.filtersDataSource);
  const setFiltersDataSource = useStore((state) => state.setFiltersDataSource);
  const onToggleDataButtonClick = useCallback(() => {
    resetForceEnabled(
      filtersDataSource === "completed" ? allAsIfCompleted.reduce((acc, value) => ({ ...acc, [value]: true }), {}) : {}
    );
    setFiltersDataSource(filtersDataSource === "completed" ? "all" : "completed");
  }, [allAsIfCompleted, filtersDataSource, resetForceEnabled, setFiltersDataSource]);

  const completed = filtersDataSource === "completed" ? actuallyCompleted : allAsIfCompleted;

  const {
    enabledComponents,
    enabledPositionedElements,
    forceEnabled,
    forceEnable,
    disableAllComponents,
    setDisableAllComponents,
    disableAllElements,
    setDisableAllElements,
  } = useFiltersState();

  const onFabricationLocationClick = useCallback(
    () => setDisableAllComponents(!disableAllComponents),
    [disableAllComponents, setDisableAllComponents]
  );
  const onElementsClick = useCallback(
    () => setDisableAllElements(!disableAllElements),
    [disableAllElements, setDisableAllElements]
  );

  const createOnItemClick = (thing) => () => {
    const keys = (() => {
      if (typeof thing === "object") {
        if (thing.semantic === "Component") return [thing.id];
        if (thing.semantic === "Element") {
          // Kinda twisted, but necessary with the current data model:
          // As the elements list is rendered with `uniqueElements` which only
          // includes ONE Element match (even though there may be many times the
          // same element across components), we need to find all corresponding elements
          // across all components to toggle all of them.
          const symbol = thing.wantedText("Symbol").value;
          const allElementsWithSymbol = client.children
            .flatMap((component) => component.children)
            .filter((element) => element.wantedText("Symbol").value === symbol);
          const allPositionedElements = allElementsWithSymbol.flatMap((element) => element.children);
          return allPositionedElements
            .filter((positionedElement) => completed.includes(positionedElement.id))
            .map((n) => n.id);
        }
      } else {
        return [thing];
      }
    })();
    keys.forEach((key) => {
      const current = forceEnabled[key];
      forceEnable(key, current === undefined ? false : !current);
    });
  };

  const renderCheckBox = (checked) => {
    return <CheckRowBox>{checked && <CheckRowMark />}</CheckRowBox>;
  };

  const renderComponentItem = (component) => {
    const pinned = completed.includes(component.id);
    const enabled = enabledComponents.includes(component);
    return (
      <CheckRow key={component.id} disabled={!pinned} onClick={createOnItemClick(component)}>
        <CheckRowIcon src={component.wantedMedia("FabricationIcon", "Icon")} />
        <CheckRowTitle>{component.wantedText("Title")}</CheckRowTitle>
        {renderCheckBox(enabled)}
      </CheckRow>
    );
  };

  const renderSpecialItem = (type, icon, title) => {
    return (
      <CheckRow key={title.value} onClick={createOnItemClick(type)}>
        <CheckRowIcon src={icon} />
        <CheckRowTitle>{title}</CheckRowTitle>
        {renderCheckBox(forceEnabled[type] === undefined ? true : forceEnabled[type])}
      </CheckRow>
    );
  };

  const renderElementItem = (element) => {
    const pinned = includesElement(completed, element);
    const enabled = element.children.filter((positioned) => enabledPositionedElements.includes(positioned)).length > 0;
    return (
      <CheckRow key={element.id} disabled={!pinned} onClick={createOnItemClick(element)}>
        <SizedElementBox symbol={element.wantedText("Symbol")} />
        <CheckRowTitle>{element.wantedText("Title")}</CheckRowTitle>
        {renderCheckBox(enabled)}
      </CheckRow>
    );
  };

  return (
    <FiltersSidebarContentRoot {...rest}>
      <Links>
        <Link action="go-back">{Strings.localized("BackToGame")}</Link>
        <Link onClick={onToggleDataButtonClick}>
          {Strings.localized(
            filtersDataSource === "completed" ? "ToggleFiltersDataSourceAll" : "ToggleFiltersDataSourceCompleted"
          )}
        </Link>
      </Links>
      <Content>
        <Section>
          <CheckRow onClick={onFabricationLocationClick}>
            <CheckRowSectionTitle large>{Strings.localized("FabricationLocations")}</CheckRowSectionTitle>
            {renderCheckBox(!disableAllComponents)}
          </CheckRow>
          <ItemsScroller>{allComponents.map((component) => renderComponentItem(component))}</ItemsScroller>
        </Section>
        <Splitter />
        <Section>
          {renderSpecialItem("assembly", resource("images/Phone_Icon.png"), Strings.localized("DeviceAssembly"))}
          {renderSpecialItem("store", resource("images/Store_Icon.png"), Strings.localized("Store"))}
        </Section>
        <Splitter />
        <Section>
          <CheckRow onClick={onElementsClick}>
            <CheckRowSectionTitle large>{Strings.localized("Elements")}</CheckRowSectionTitle>
            {renderCheckBox(!disableAllElements)}
          </CheckRow>
          <ItemsScroller>{uniqueElements.map((element) => renderElementItem(element))}</ItemsScroller>
        </Section>
      </Content>
    </FiltersSidebarContentRoot>
  );
};

FiltersSidebarContent.propTypes = {};

export default FiltersSidebarContent;
