import { Button, Image, resource, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { darkGreenColor, greenColor } from "../../common";

export const SidebarRoot = styled.div`
  width: 20vw;
  padding: 0.5vh;
  background-color: ${greenColor};
  display: flex;
`;

export const Frame = styled.div`
  position: relative;
  border: 0.5vh solid ${darkGreenColor};
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const Title = staggerStep({ transition: "slide-in-and-fade-from-top" })(styled(Text)`
  margin-top: 1.5vh;
  font-family: "Futura-Bold";
  font-size: 1.3vh;
  color: white;
  text-align: center;
  align-self: center;
`);

export const SessionCodeButton = staggerStep({ transition: "slide-in-and-fade-from-top" })(styled(Button)`
  margin-top: 0.8vh;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`);

export const SessionCodeText = styled(Text)`
  font-family: "Futura-Bold";
  font-size: 1.3vh;
  text-align: center;
  color: ${darkGreenColor};
`;

export const SessionCodeSymbol = styled(Image).attrs((props) => ({
  src: resource("images/Clipboard_Symbol_Blue.svg"),
}))`
  margin-left: 1vh;
  width: 2vh;
  height: 2vh;
`;

export const DecorationsA = styled(Image).attrs((props) => ({ src: resource("images/Decorations_A.png") }))`
  position: absolute;
  width: 6vh;
  top: 30vh;
  right: 3vh;
`;

export const DecorationsB = styled(Image).attrs((props) => ({ src: resource("images/Decorations_B.png") }))`
  position: absolute;
  width: 8vh;
  top: 9vh;
  left: 2vh;
`;

export const DecorationsC = styled(Image).attrs((props) => ({ src: resource("images/Decorations_C.png") }))`
  position: absolute;
  width: 6vh;
  bottom: 7vh;
  left: 4vh;
`;

export const DecorationsD = styled(Image).attrs((props) => ({ src: resource("images/Decorations_D.png") }))`
  position: absolute;
  width: 8vh;
  bottom: 7vh;
  right: 2vh;
`;

export const Content = styled.div`
  margin-top: 1.2vh;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
