// This triggers webpack bundling of styles.
// The styles are injected in a <style> tag at runtime,
// which makes them HMR-compatible.
import { App, Env, resource } from "ripple";
import "../styles/main.less";
import nav from "./core/nav";
import stats from "./core/stats";
import AppRoot from "./react/app-root";
import localEpic from "./redux/local/local-epic";
import localReducer from "./redux/local/local-reducer";
import masterEpic from "./redux/master/master-epic";
import masterReducer from "./redux/master/master-reducer";

App.bootstrap({
  element: document.querySelector("#app"),
  appRoot: AppRoot,
  home: () => null, // Override the configured home based on runtime information such as localStorage data (for example to show a tutorial on first run and another page on subsequent runs)
  nav: nav, // Dynamic navigation logic
  stats: stats, // Custom stats to add in the `/analytics` dashboard
  localReducer: localReducer, // Root reducer for the `local` portion of the local store
  localEpic: localEpic, // Epics triggered by dispatches to the local store
  masterReducer: masterReducer, // Root reducer for the IPC master store (maintained by the IPC master)
  masterEpic: masterEpic, // Epics triggered by IPC dispatches (run on the master store maintained by the IPC master)
  fonts: {
    custom: {
      families: [
        "Gotham",
        "GothamBlack",
        "GothamBold",
        "GothamBook",
        "GothamCondensed",
        "GothamExLight",
        "GothamLight",
        "GothamMedium",
        "Futura-Bold",
        "Futura-MediumItalic",
        "Futura-Medium",
        "Futura-CondensedExtraBold",
        "Futura-CondensedMedium",
        "Futura-MediumItalic",
        "Futura-CondensedMedium",
      ],
      urls: [resource("fonts/gotham/stylesheet.css"), resource("fonts/futura/stylesheet.css")],
    },
  },
  onStart: () => {
    if (__DEV__) Env.openInstance("second");
  },
});
