import PropTypes from "prop-types";
import { Placeholder, PortraitBlockerRoot } from "./styled";

const PortraitBlocker = ({ children, ...rest }) => {
  return <PortraitBlockerRoot {...rest}>{children ?? <Placeholder>Portrait Blocked</Placeholder>}</PortraitBlockerRoot>;
};

PortraitBlocker.propTypes = {
  children: PropTypes.node,
};

export default PortraitBlocker;
