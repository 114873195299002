import { useCallback } from "react";
import { Navigator, Strings, Toast } from "ripple";
import Api from "../../api";

export default (code) => {
  return useCallback(() => {
    Api.getSession(code)
      .then((session) => {
        localStorage.setItem("session-code", code);
        Navigator.navigate({ type: "components" });
      })
      .catch((error) => {
        if (error.status === 404) Toast.warn(Strings.localized("SessionDoesNotExist").value);
        else Toast.warn(Strings.localized("CouldNotJoinSession").value + ` (${error.message})`);
      });
  }, [code]);
};
