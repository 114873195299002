import { resource, Strings, useLanguage } from "ripple";
import { Link } from "../../common";
import { BottomLogo, EmptySidebarContentRoot } from "./styled";

const EmptySidebarContent = ({ ...rest }) => {
  const language = useLanguage();
  return (
    <EmptySidebarContentRoot {...rest}>
      {localStorage.getItem("specmode") && (
        <Link navigate={{ type: "filters" }}>{Strings.localized("FinalMapLink")}</Link>
      )}
      <BottomLogo src={resource(`images/Logo_ConnectedThroughMinerals_${language.toUpperCase()}.png`)} />
    </EmptySidebarContentRoot>
  );
};

EmptySidebarContent.propTypes = {};

export default EmptySidebarContent;
