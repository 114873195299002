import { Button, Image, resource, staggerStep, Text } from "ripple";
import styled from "styled-components";

export const simbiozBlue = "#00a5e3";
export const greenColor = "#02828C";
export const darkGreenColor = "#15404A";
export const almostBlack = "#1A1A1A";

export const StandardPillButton = styled(Button)`
  background-color: ${darkGreenColor};
  color: white;
  font-size: 1vmax;
  padding: 1.3vmin 2.4vmin;
  border-radius: 2.5vmin;
  text-align: center;
  font-family: "GothamBlack";

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 0.3;
  }

  &.clicked {
    transition: transform 100ms ease-in-out;
    transform: scale3d(0.95, 0.95, 1);
  }
`;

export const BottomLeftConnectedLogo = staggerStep({})(
  styled(Image)`
    position: absolute;
    transform-origin: 0 100%;
    transform: scale3d(0.65, 0.65, 1);
    bottom: 5vh;
    left: 5vh;
  `
);

export const BottomRightMuseumLogo = staggerStep({})(
  styled(Image).attrs((props) => ({ src: resource("images/Logo_Musee.png") }))`
    position: absolute;
    transform-origin: 100% 100%;
    transform: scale3d(0.65, 0.65, 1);
    bottom: 5vh;
    right: 5vh;
  `
);

export const StandardTitle = styled(Text)`
  margin-top: 2vh;
  font-family: "Futura-CondensedMedium";
  font-size: 6vh;
  align-self: center;
  text-align: center;
`;

export const StandardSubtitle = styled(Text)`
  margin-top: 1vh;
  font-size: 2.3vh;
  align-self: center;
  text-align: center;
  max-width: 60%;
  font-family: "GothamLight";
  color: ${darkGreenColor};

  b,
  strong {
    font-family: "GothamBold";
  }
`;

export const Links = styled.div`
  margin-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Link = styled(Button)`
  margin: 1vh;
  color: white;
  text-decoration: underline;
  font-size: 1.1vh;
  text-align: center;
  align-self: center;
`;
