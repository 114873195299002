import { transparentize } from "polished";
import { Image, Paragraphs, resource, Scroller, Text } from "ripple";
import styled from "styled-components";
import { almostBlack, darkGreenColor, greenColor } from "../../common";
import ElementBox from "../element-box";

export const TeamContentRootScroller = styled(Scroller).attrs((props) => ({
  scrollbarInset: 20,
  scrollbarStartInset: 60,
}))`
  width: 100%;
  height: 100%;

  .scroller-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scroller-track {
    width: 0.5vh;
  }

  .scroller-thumb {
    background-color: ${greenColor};
    opacity: 0.5;
    border-radius: 0.25vh;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const Title = styled(Text)`
  margin-top: 4vh;
  width: 100%;
  text-align: center;
  font-family: "Futura-Medium";
  text-transform: uppercase;
  font-size: 4vh;
  color: ${almostBlack};
`;

export const Description = styled(Paragraphs)`
  margin: 0 10vh;
  margin-top: 1.4vh;
  font-family: "GothamLight";
  font-size: 1.8vh;
  color: ${almostBlack};
  text-align: ${(props) => (props.centered ? "center" : "left")};

  b,
  strong {
    font-family: "GothamBold";
  }

  h1 {
    margin-top: 2.5vh;
    font-family: "Futura-Medium";
    font-size: 2.5vh;
  }
`;

export const TaskHeader = styled.div`
  margin-top: 4.5vh;
  width: 100%;
  background-color: ${darkGreenColor};
  color: white;
  padding: 1.2vh;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TaskHeaderImage = styled(Image)`
  margin-right: 0.8vh;
  height: 6vh;
  width: 6vh;
`;

export const TaskHeaderText = styled(Text)`
  font-family: "Futura-Medium";
  font-size: 2.5vh;
`;

export const TaskHeaderCheck = styled(Image).attrs((props) => ({ src: resource("images/Check_Sidebar.svg") }))`
  margin-left: 1.5vh;
  transition: opacity 100ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  height: 5vh;
  width: 5vh;
`;

export const TaskBox = styled(Paragraphs)`
  margin-top: 2.2vh;
  width: 100%;
  padding: 4vh 10vh;
  background-color: ${greenColor};
  color: white;
  font-size: 1.8vh;
  font-family: "GothamLight";

  b,
  strong {
    font-family: "GothamBold";
  }

  h1 {
    margin-top: 6vh;
    font-family: "Futura-Medium";
    font-size: 2.5vh;
  }

  h1:first-child {
    margin-top: 0;
  }
`;

export const InfoLocationImage = styled(Image).attrs((props) => ({
  src: resource("images/MapCoordinates_Example.png"),
}))`
  max-width: 50%;
  align-self: center;
`;

export const ElementRow = styled.div`
  margin: 1.5vh 14vh;
  display: flex;
  flex-direction: row;
`;

export const ElementIcon = styled(ElementBox)`
  margin-right: 1.2vh;
  min-width: 2.5vh;
  width: 2.5vh;
  min-height: 2.5vh;
  height: 2.5vh;
  font-size: 1.6vh;
`;

export const ElementTitle = styled(Text)`
  font-family: "Futura-Medium";
  font-size: 2.4vh;
`;

export const ElementDescription = styled(Paragraphs)`
  font-family: "GothamLight";
  font-size: 1.6vh;

  p {
    margin: 1vh 0;
  }
`;

export const ElementColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ElementsTable = styled.table`
  margin-top: 6vh;
  margin-bottom: 20vh;
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: ${greenColor};
    font-family: "GothamBlack";
    font-size: 2vh;
    color: white;
    padding: 1vh;
    border: 1px solid ${greenColor};
  }

  td {
    color: ${darkGreenColor};
    font-size: 2vh;
    text-align: center;
    border: 1px solid ${almostBlack};
    padding: 1vh;
    padding-left: 4vh;
    text-align: left;
  }

  td.element {
    padding-left: 2vh;
  }

  td.col1 {
    width: 24%;
  }

  td.col2 {
    width: 38%;
    border-bottom: 2px dashed ${transparentize(0.5, greenColor)};
  }

  td.col3 {
    width: 38%;
    border-bottom: 2px dashed ${transparentize(0.5, greenColor)};
  }

  td.top-row {
    border-top: 1px solid ${darkGreenColor};
  }

  td.bottom-row {
    border-bottom: 1px solid ${darkGreenColor};
  }

  tr.darker {
    background-color: #eeeff0;
  }
`;

export const TableElementCellContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableElementBox = styled(ElementBox)`
  margin-right: 2vh;
  min-width: 8vh;
  width: 8vh;
  min-height: 8vh;
  height: 8vh;
  border: 0.5vh solid ${darkGreenColor};
  color: ${darkGreenColor};
  font-size: 4vh;
`;

export const NumberedList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumberedListItem = styled.div`
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const NumberedListMarker = styled.div`
  width: 3vh;
  min-width: 3vh;
  height: 3vh;
  border-radius: 50%;
  background-color: white;
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NumberedListText = styled(Text)`
  margin-left: 2vh;
  margin-top: 0.5vh;
`;
